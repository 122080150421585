@charset "UTF-8";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

/* css-side-effects-plugin-ns:app/components/table/BasicDatatable.css */
@media (max-width: 650px) {
  table:not(.table-bordered).no-first-header.show-serial td:first-child:not(:last-child)::before {
    content: "SL.";
  }
  .table:not(.table-bordered) td:first-child,
  .table:not(.table-bordered) th:first-child,
  .table:not(.table-bordered) tr:first-child {
    padding-left: 8.8px;
  }
  th {
    display: none;
  }
  td:not(:last-child) {
    display: grid;
    grid-template-columns: 17ch auto;
    word-break: break-word;
    width: 100%;
  }
  table.priceing td:not(:last-child) {
    grid-template-columns: unset !important;
  }
  td::before {
    content: attr(data-header) " ";
    font-weight: 600;
    text-transform: capitalize;
    color: #3d3d3d;
  }
  table tbody td:last-child:not([colspan]) {
    display: grid;
    grid-template-columns: 17ch 5ch 5ch 5ch;
    align-items: center;
    text-align: start !important;
    width: 100%;
  }
  table tbody td[data-label]::before {
    content: attr(data-label) " ";
    font-weight: 600;
    text-transform: capitalize;
    color: #3d3d3d;
  }
  td[data-label] {
    text-align: start !important;
    display: grid;
    grid-template-columns: 20ch auto;
    align-items: center;
    width: 100%;
  }
}

/* css-side-effects-plugin-ns:app/components/table/customtable.css */
@media (max-width: 650px) {
  .custom-table {
    overflow-x: auto;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/business-list/businesslist.css */
.product_list_section {
  margin-top: 80px;
  padding: 50px 0;
}
.product_list_section .filter_content {
  position: relative;
  margin-bottom: 20px;
}
.product_list_section .filter_content .filter_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 24px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ececec;
}
.product_list_section .filter_content .filter_title_box .title {
  color: #3c4242;
  font-family: "Google Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  margin: 0;
}
.product_list_section .filter_content .filter_title_box .icon_box {
  height: 30px;
  width: 30px;
}
.product_list_section .filter_content .filter_title_box .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_list_section .filter_content .content_box .category_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 0px;
  transition: all 250ms;
}
.product_list_section .filter_content .content_box .category_list li.active {
  background-color: #b3b3b340;
  border-radius: 3px;
}
.product_list_section .filter_content .content_box .category_list li,
.product_list_section .filter_content .content_box .category_list li * {
  cursor: pointer;
}
.product_list_section .filter_content .content_box .category_list li .text {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.product_list_section .product_list_content .products {
  padding: 24px 0;
}
.product_list_section .product_list_content .products .product_card {
  margin-bottom: 24px;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/filter-accordion/filteraccordion.css */
.filter_accordion .title_box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-bottom: 1px solid #ececec;
}
.filter_accordion .title_box .icon_box {
  width: 15px;
  height: 30px;
  transition: all 250ms;
  transform: rotate(180deg);
}
.filter_accordion .title_box .icon_box svg {
  width: 100%;
  height: 100%;
}
.filter_accordion .title_box h2 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  margin: 0;
}
.filter_accordion .title_box,
.filter_accordion .title_box * {
  cursor: pointer;
}
.filter_accordion .content_box {
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: all 250ms;
}
.filter_accordion.active .title_box .icon_box {
  transform: rotate(0deg);
}
.filter_accordion.active .title_box .icon_box svg {
  width: 100%;
  height: 100%;
}
.filter_accordion.active .content_box {
  padding: 16px 0px;
  height: auto;
  border-bottom: 1px solid #ececec;
}

/* css-side-effects-plugin-ns:node_modules/react-datepicker/dist/react-datepicker.css */
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #ccc;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}
.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}
.react-datepicker {
  font-family:
    "Helvetica Neue",
    helvetica,
    arial,
    sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}
.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time,
.react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker-popper {
  z-index: 1;
  line-height: 0;
}
.react-datepicker-popper .react-datepicker__triangle {
  stroke: #aeaeae;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  fill: #f0f0f0;
  color: #f0f0f0;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
  fill: #fff;
  color: #fff;
}
.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 8px 0;
  position: relative;
}
.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}
.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}
.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 15px;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}
h2.react-datepicker__current-month {
  padding: 0;
  margin: 0;
}
.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border: none;
  z-index: 1;
  height: 32px;
  width: 32px;
  text-indent: -999em;
  overflow: hidden;
}
.react-datepicker__navigation--previous {
  left: 2px;
}
.react-datepicker__navigation--next {
  right: 2px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 85px;
}
.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__navigation--years-previous {
  top: 4px;
}
.react-datepicker__navigation--years-upcoming {
  top: -4px;
}
.react-datepicker__navigation:hover *::before {
  border-color: rgb(165.75, 165.75, 165.75);
}
.react-datepicker__navigation-icon {
  position: relative;
  top: -1px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation-icon--next {
  left: -2px;
}
.react-datepicker__navigation-icon--next::before {
  transform: rotate(45deg);
  left: -7px;
}
.react-datepicker__navigation-icon--previous {
  right: -2px;
}
.react-datepicker__navigation-icon--previous::before {
  transform: rotate(225deg);
  right: -7px;
}
.react-datepicker__month-container {
  float: left;
}
.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}
.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}
.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}
.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}
.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: auto;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
  -moz-appearance: textfield;
}
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}
.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}
.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -87px;
  top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + 1.7rem / 2);
  overflow-y: scroll;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}
.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:not(.react-datepicker__week-number--selected, .react-datepicker__week-number--keyboard-selected):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__week-number--selected {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__week-number--selected:hover {
  background-color: rgb(28.75, 93.2196969697, 143.75);
}
.react-datepicker__week-number--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(41.5, 134.5606060606, 207.5);
  color: #fff;
}
.react-datepicker__week-number--keyboard-selected:hover {
  background-color: rgb(28.75, 93.2196969697, 143.75);
}
.react-datepicker__day-names {
  white-space: nowrap;
  margin-bottom: -8px;
}
.react-datepicker__week {
  white-space: nowrap;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}
.react-datepicker__day:not([aria-disabled=true]):hover,
.react-datepicker__month-text:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text:not([aria-disabled=true]):hover,
.react-datepicker__year-text:not([aria-disabled=true]):hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}
.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}
.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}
.react-datepicker__day--highlighted:not([aria-disabled=true]):hover,
.react-datepicker__month-text--highlighted:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--highlighted:not([aria-disabled=true]):hover,
.react-datepicker__year-text--highlighted:not([aria-disabled=true]):hover {
  background-color: rgb(49.8551020408, 189.6448979592, 62.5632653061);
}
.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}
.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}
.react-datepicker__day--holidays,
.react-datepicker__month-text--holidays,
.react-datepicker__quarter-text--holidays,
.react-datepicker__year-text--holidays {
  position: relative;
  border-radius: 0.3rem;
  background-color: #ff6803;
  color: #fff;
}
.react-datepicker__day--holidays .overlay,
.react-datepicker__month-text--holidays .overlay,
.react-datepicker__quarter-text--holidays .overlay,
.react-datepicker__year-text--holidays .overlay {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__day--holidays:not([aria-disabled=true]):hover,
.react-datepicker__month-text--holidays:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--holidays:not([aria-disabled=true]):hover,
.react-datepicker__year-text--holidays:not([aria-disabled=true]):hover {
  background-color: rgb(207, 82.9642857143, 0);
}
.react-datepicker__day--holidays:hover .overlay,
.react-datepicker__month-text--holidays:hover .overlay,
.react-datepicker__quarter-text--holidays:hover .overlay,
.react-datepicker__year-text--holidays:hover .overlay {
  visibility: visible;
  opacity: 1;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
.react-datepicker__day--selected:not([aria-disabled=true]):hover,
.react-datepicker__day--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__day--in-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__month-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--in-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--selected:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-selecting-range:not([aria-disabled=true]):hover,
.react-datepicker__year-text--in-range:not([aria-disabled=true]):hover {
  background-color: rgb(28.75, 93.2196969697, 143.75);
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: rgb(186.25, 217.0833333333, 241.25);
  color: rgb(0, 0, 0);
}
.react-datepicker__day--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__month-text--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__quarter-text--keyboard-selected:not([aria-disabled=true]):hover,
.react-datepicker__year-text--keyboard-selected:not([aria-disabled=true]):hover {
  background-color: rgb(28.75, 93.2196969697, 143.75);
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range),
.react-datepicker__year--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}
.react-datepicker__day--disabled .overlay,
.react-datepicker__month-text--disabled .overlay,
.react-datepicker__quarter-text--disabled .overlay,
.react-datepicker__year-text--disabled .overlay {
  position: absolute;
  bottom: 70%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px;
  border-radius: 4px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease-in-out;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 25px;
}
.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
  position: relative;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: rgb(178.5, 178.5, 178.5);
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: -16px;
  top: 0;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}
.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: rgb(178.5, 178.5, 178.5);
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: rgb(178.5, 178.5, 178.5);
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}
.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0 6px 0 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\d7";
}
.react-datepicker__close-icon--disabled {
  cursor: default;
}
.react-datepicker__close-icon--disabled::after {
  cursor: default;
  background-color: #ccc;
}
.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}
.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}
.react-datepicker__children-container {
  width: 13.8rem;
  margin: 0.4rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  height: auto;
}
.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}
.react-datepicker__calendar-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.125em;
}

/* css-side-effects-plugin-ns:app/components/landingPage/layouts/header/header.css */
.lp_header {
  padding: 10px 0px;
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  background: #f5f7fa;
  --color: #18191f;
  --fill: #7e8299;
  --hover: #ebecf1;
}
.custom_container {
  min-height: 50vh;
  margin-top: 3.5rem;
}
.custom_input {
  height: 50px;
  width: 100%;
  border: 0;
  font-size: x-large;
  font-weight: bold;
  border-radius: 5px;
  background-color: transparent;
  color: white;
}
.lp_header .logo_light {
  display: block;
}
.lp_header .logo_dark {
  display: none;
}
.lp_header.dark {
  background: #4b4e67;
  --color: #ffffff;
  --fill: #ffffff;
  --hover: #515151;
}
.lp_header.dark .logo_light {
  display: none;
}
.lp_header.dark .logo_dark {
  display: block;
}
.lp_header.black {
  background: #111111;
  --color: #ffffff;
  --fill: #ffffff;
  --hover: #515151;
}
.lp_header.black .logo_light {
  display: none;
}
.lp_header.black .logo_dark {
  display: block;
}
.lp_header .content_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lp_header .content_wrapper .left_box .imgbox {
  width: 230px;
}
.lp_header .content_wrapper .center_box .menu {
  display: flex;
  align-items: center;
  gap: 50px;
}
.lp_header .content_wrapper .center_box .menu li a {
  color: var(--color);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all 350ms;
}
.lp_header .content_wrapper .center_box .menu li {
  position: relative;
}
.lp_header .content_wrapper .center_box .menu li::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  width: 0%;
  height: 2px;
  border-radius: 10px;
  background-color: #8e93be;
  transition: all 350ms;
}
.lp_header .content_wrapper .center_box .menu li.active a,
.lp_header .content_wrapper .center_box .menu li:hover a {
  color: #8e93be;
}
.lp_header .content_wrapper .center_box .menu li.active::before,
.lp_header .content_wrapper .center_box .menu li:hover::before {
  width: 100%;
}
.lp_header .content_wrapper .center_box .menu li a,
.lp_header .content_wrapper .center_box .menu li a * {
  cursor: pointer;
}
.lp_header .content_wrapper .center_box .menu li .c_btn {
  padding: 15px 25px;
  border-radius: 3px;
  background: #fff;
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  position: relative;
  transition: all linear 350ms;
}
.lp_header .content_wrapper .center_box .menu li .c_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.lp_header .content_wrapper .center_box .menu li .c_btn:hover::before {
  width: 100%;
}
.lp_header .content_wrapper .right_box {
  display: flex;
  align-items: center;
  gap: 25px;
}
.lp_header .content_wrapper .right_box .icon_list {
  display: flex;
  align-items: center;
  gap: 25px;
}
.lp_header .content_wrapper .right_box .icon_list li {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_header .content_wrapper .right_box .icon_list li svg {
  width: 20px;
  height: 20px;
}
.lp_header .content_wrapper .right_box .icon_list li svg * {
  fill: var(--fill);
}
.lp_header .content_wrapper .right_box .icon_list li,
.lp_header .content_wrapper .right_box .icon_list li * {
  cursor: pointer;
}
.lp_header .content_wrapper .right_box .icon_list li.active {
  background-color: var(--hover);
}
.lp_header .content_wrapper .right_box .menu_controller {
  width: 20px;
  height: 20px;
  display: none;
  color: var(--fill);
}
.lp_header .content_wrapper .right_box .menu_controller svg {
  width: 100%;
  height: 100%;
}
.lp_header .content_wrapper .right_box .menu_controller svg * {
  fill: currentColor;
}
.lp_header .content_wrapper .right_box .menu_controller,
.lp_header .content_wrapper .right_box .menu_controller * {
  cursor: pointer;
}
.lp_header .mobile_menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: calc(100vh - 100%);
  padding: 0 0 0;
  transition: all 350ms;
  background-color: #ffffff;
  z-index: 1;
}
.lp_header .mobile_menu .container {
  height: 100%;
}
.lp_header .mobile_menu .menu {
  height: calc(100% - 60px);
  display: block;
}
.lp_header .mobile_menu .menu li {
  padding: 10px;
}
.lp_header .mobile_menu .menu li a {
  color: #18191f;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.lp_header .mobile_menu .menu li a,
.lp_header .mobile_menu .menu li a * {
  cursor: pointer;
}
.lp_header .mobile_menu .icon_list {
  display: none;
  align-items: center;
  gap: 25px;
  height: 60px;
  padding: 20px;
}
.lp_header .mobile_menu .icon_list li {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_header .mobile_menu .icon_list li svg {
  width: 20px;
  height: 20px;
}
.lp_header .mobile_menu .icon_list li,
.lp_header .mobile_menu .icon_list li * {
  cursor: pointer;
}
.lp_header .mobile_menu .icon_list li.active {
  background-color: #ebecf1;
}
@media only screen and (max-width: 1200px) {
  .lp_header .content_wrapper .center_box .menu {
    gap: 25px;
  }
  .lp_header .content_wrapper .right_box .icon_list {
    gap: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .lp_header .content_wrapper .center_box {
    display: none;
  }
  .lp_header .content_wrapper .center_box .menu {
    gap: 25px;
  }
  .lp_header .content_wrapper .right_box .menu_controller {
    display: block;
  }
  .lp_header .mobile_menu {
    display: block;
  }
  .lp_header .mobile_menu.active {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 768px) {
  .lp_header .content_wrapper .left_box .imgbox {
    width: 200px;
  }
  .lp_header .content_wrapper .right_box .icon_list {
    display: none;
  }
  .lp_header .mobile_menu {
    display: block;
  }
  .lp_header .mobile_menu .icon_list {
    display: flex;
    gap: 5px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/layouts/footer/footer.css */
.lp_footer {
  padding: 64px 0;
  background-color: #4b4e67;
}
.lp_footer .content_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 30px;
}
.lp_footer .content_wrapper .footer_blocks {
  width: calc(20% - 24px);
  flex-shrink: 0;
}
.lp_footer .footer_title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 40px;
}
.lp_footer .footer_details .logo {
  display: block;
  width: 100%;
  max-width: 250px;
  margin-bottom: 40px;
}
.lp_footer .footer_details .texts {
  color: #f5f7fa;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 40px;
}
.lp_footer .footer_details .social_icons {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
}
.lp_footer .footer_details .social_icons li svg {
  height: 32px;
  width: 32px;
  fill: #fff;
}
.lp_footer .footer_menu .menu li {
  margin-bottom: 10px;
}
.lp_footer .footer_menu .menu li a {
  color: #f5f7fa;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
  transition: all 250ms;
}
.lp_footer .footer_menu .menu li a:hover {
  color: #fff;
}
.lp_footer .footer_menu .menu li a,
.lp_footer .footer_menu .menu li a * {
  cursor: pointer;
}
.lp_footer .footer_contacts .contact_list li {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 12px;
}
.lp_footer .footer_contacts .contact_list li .icon_box {
  height: 30px;
  width: 30px;
  padding: 5px;
  border: 1px solid #fff;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_footer .footer_contacts .contact_list li .icon_box svg {
  width: 100%;
  height: 100%;
}
.lp_footer .footer_contacts .contact_list li .text_box,
.lp_footer .footer_contacts .contact_list li .text_box * {
  color: #fafafa;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.lp_footer .footer_contacts .contact_list li .text_box a {
  transition: all 250ms;
}
.lp_footer .footer_contacts .contact_list li .text_box a:hover {
  color: #fff;
}
.lp_footer .footer_contacts .contact_list li .text_box a,
.lp_footer .footer_contacts .contact_list li .text_box a * {
  cursor: pointer;
}
.lp_footer .footer_email .email_form {
  width: 100%;
  max-width: 250px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.3137254902);
}
.lp_footer .footer_email .email_form input {
  padding: 10px 12px;
  background-color: transparent;
  border: none;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 80%;
}
.lp_footer .footer_email .email_form input::-webkit-input-placeholder {
  color: #d9dbe1;
}
.lp_footer .footer_email .email_form button {
  height: 100%;
  width: 20%;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_footer .footer_email .email_form button,
.lp_footer .footer_email .email_form button * {
  cursor: pointer;
}
.lp_footer .footer_email .email_form button svg {
  width: 30px;
  height: 30px;
}
@media only screen and (max-width: 992px) {
  .lp_footer .content_wrapper .footer_blocks {
    width: calc(33.3333333333% - 20px);
  }
}
@media only screen and (max-width: 768px) {
  .lp_footer .content_wrapper .footer_blocks {
    width: 100%;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/price-range/pricerange.css */
.price_range_slector {
  padding: 16px;
}
.price_range_slector .price-input {
  width: 100%;
  display: flex;
  margin: 30px 0 35px;
}
.price_range_slector .price-input .field {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}
.price_range_slector .field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}
.price_range_slector input[type=number]::-webkit-outer-spin-button,
.price_range_slector input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.price_range_slector .price-input .separator {
  width: 130px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}
.price_range_slector .slider {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}
.price_range_slector .slider .progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #4b4e67;
}
.price_range_slector .range-input {
  position: relative;
}
.price_range_slector .range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.price_range_slector input[type=range]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #4b4e67;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  cursor: grab;
}
.price_range_slector input[type=range]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #4b4e67;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  cursor: grab;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/product-list/productlist.css */
.product_list_section {
  margin-top: 80px;
  padding: 50px 0;
}
.product_list_section .filter_content {
  position: relative;
  margin-bottom: 20px;
}
.product_list_section .filter_content .filter_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 24px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ececec;
}
.product_list_section .filter_content .filter_title_box .title {
  color: #3c4242;
  font-family: "Google Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
  margin: 0;
}
.product_list_section .filter_content .filter_title_box .icon_box {
  height: 30px;
  width: 30px;
}
.product_list_section .filter_content .filter_title_box .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_list_section .filter_content .content_box .category_list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-bottom: 0px;
  transition: all 250ms;
}
.product_list_section .filter_content .content_box .category_list li.active {
  background-color: #b3b3b340;
  border-radius: 3px;
}
.product_list_section .filter_content .content_box .category_list li,
.product_list_section .filter_content .content_box .category_list li * {
  cursor: pointer;
}
.product_list_section .filter_content .content_box .category_list li .text {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
}
.product_list_section .product_list_content .products {
  padding: 24px 0;
}
.product_list_section .product_list_content .products .product_card {
  margin-bottom: 24px;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/career-list/career_list.css */
.career_list_section {
  margin-top: 80px;
  padding: 50px 0;
}

/* css-side-effects-plugin-ns:app/components/table/auth-invoice.css */
@media (max-width: 650px) {
  .table.subscription-details-table {
    width: 100%;
    display: table !important;
  }
  .table.subscription-details-table > :not(caption) > * > * {
    padding-left: 0 !important;
  }
  .table.subscription-details-table tbody {
    display: table-row-group !important;
  }
  .table.subscription-details-table tr {
    display: table-row !important;
  }
  .table.subscription-details-table th {
    display: none;
  }
  .table.subscription-details-table td {
    display: grid !important;
    grid-template-columns: 17ch auto !important;
    width: 100% !important;
  }
  .table.subscription-details-table td::before {
    content: attr(data-cell) " ";
    font-weight: 700;
    text-transform: capitalize;
    color: #3d3d3d;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/product-gallery/productgallery.css */
.product_image {
  width: calc(20% - 16px);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.product_image .main_slider {
  margin-bottom: 10px;
}
.product_image .main_slider .swiper-button-next,
.product_image .main_slider .swiper-button-prev {
  width: 30px;
  height: 30px;
  padding: 7px;
  color: #959595;
  background-color: rgba(255, 255, 255, 0.3137254902);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_image .main_slider .swiper-button-next svg,
.product_image .main_slider .swiper-button-prev svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.product_image .main_slider .swiper-button-next::after,
.product_image .main_slider .swiper-button-prev::after {
  content: none;
}
.product_image .thumb_slider .swiper-slide .imgbox_full {
  border-radius: 2px;
  padding: 5px;
}
.product_image .thumb_slider .swiper-slide .imgbox_full,
.product_image .thumb_slider .swiper-slide .imgbox_full * {
  cursor: pointer;
}
.product_image .imgbox_full {
  max-width: 300px;
  margin: 0 auto;
  aspect-ratio: 1/1;
}

/* css-side-effects-plugin-ns:node_modules/slick-carousel/slick/slick.css */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* css-side-effects-plugin-ns:node_modules/slick-carousel/slick/slick-theme.css */
.slick-loading .slick-list {
  background: #fff url(/build/_assets/ajax-loader-BOAPMZMT.gif) center center no-repeat;
}
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;
  src: url(/build/_assets/slick-EVLS6IWX.eot);
  src:
    url(/build/_assets/slick-EVLS6IWX.eot?#iefix) format("embedded-opentype"),
    url(/build/_assets/slick-MU5EZO5G.woff) format("woff"),
    url(/build/_assets/slick-NKQ64RRA.ttf) format("truetype"),
    url(/build/_assets/slick-7CK47X3J.svg#slick) format("svg");
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: "\2190";
}
[dir=rtl] .slick-prev:before {
  content: "\2192";
}
.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: "\2192";
}
[dir=rtl] .slick-next:before {
  content: "\2190";
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "\2022";
  text-align: center;
  opacity: .25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/main-product/mainproduct.css */
.main_product_section {
  margin-top: 80px;
  padding: 32px 0;
}
.main_product_section .product_content {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
}
.main_product_section .product_content .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.main_product_section .product_content .product_details,
.main_product_section .product_content .product_tags {
  width: calc(40% - 8px);
}
.main_product_section .product_content .product_details .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 11px 0;
}
.main_product_section .product_content .product_details .product_price .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.main_product_section .product_content .product_details .product_price .main_price .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.main_product_section .product_content .product_details .product_price .main_price .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .unit_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
}
.main_product_section .product_content .product_details .unit_box .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .unit_box .unit {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .tags_content {
  margin: 8px 0 12px;
}
.main_product_section .product_content .product_details .tags_content .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.main_product_section .product_content .product_details .tags_content .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #4b4e67;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main_product_section .product_content .product_tags .tag_line {
  color: #959595;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.main_product_section .product_content .product_tags .contact_list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}
.main_product_section .product_content .product_tags .contact_list li a {
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  position: relative;
  overflow: hidden;
}
.main_product_section .product_content .product_tags .contact_list li a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section .product_content .product_tags .contact_list li a:hover::before {
  width: 100%;
}
.main_product_section .product_content .product_tags .contact_list li a,
.main_product_section .product_content .product_tags .contact_list li a * {
  cursor: pointer;
}
.main_product_section .product_content .product_tags .contact_list li a svg {
  width: 100%;
  height: 100%;
}
.main_product_section .product_content .product_tags .button_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 12px;
}
.main_product_section .product_content .product_tags .button_box .follow_btn,
.main_product_section .product_content .product_tags .button_box .chat_btn {
  width: 40px;
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background: #fff;
  padding: 1.5px;
  color: rgba(0, 0, 0, 0.3137254902);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
}
.main_product_section .product_content .product_tags .button_box .follow_btn::before,
.main_product_section .product_content .product_tags .button_box .chat_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section .product_content .product_tags .button_box .follow_btn:hover::before,
.main_product_section .product_content .product_tags .button_box .chat_btn:hover::before {
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .follow_btn,
.main_product_section .product_content .product_tags .button_box .follow_btn *,
.main_product_section .product_content .product_tags .button_box .chat_btn,
.main_product_section .product_content .product_tags .button_box .chat_btn * {
  cursor: pointer;
}
.main_product_section .product_content .product_tags .button_box .follow_btn .icon_box,
.main_product_section .product_content .product_tags .button_box .chat_btn .icon_box {
  width: 20px;
  height: 20px;
  padding: 2.464px 1.267px 2.466px 1.749px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_product_section .product_content .product_tags .button_box .follow_btn .icon_box svg,
.main_product_section .product_content .product_tags .button_box .chat_btn .icon_box svg {
  height: 100%;
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .connect_btn {
  width: calc(100% - 120px);
  border: none;
  border-radius: 8px;
  background: #4b4e67;
  padding: 6px 24px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.main_product_section .product_content .product_tags .button_box .connect_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section .product_content .product_tags .button_box .connect_btn:hover::before {
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .connect_btn,
.main_product_section .product_content .product_tags .button_box .connect_btn * {
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .main_product_section .product_content .product_image {
    width: calc(40% - 8px);
  }
  .main_product_section .product_content .product_details {
    width: calc(60% - 8px);
  }
  .main_product_section .product_content .product_tags {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .main_product_section .product_content .product_image,
  .main_product_section .product_content .product_details,
  .main_product_section .product_content .product_tags {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .main_product_section .product_details .list_box .list_content {
    width: 100%;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/product-info/productinfo.css */
.product_info_section {
  padding: 94px 0 60px;
}
.product_info_section .cm_title_box {
  margin-bottom: 40px;
}
.product_info_section .product_info_tabs .tab_triggers {
  display: flex;
  gap: 16px;
}
.product_info_section .product_info_tabs .tab_triggers .trigger {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0 12px 0;
  margin-bottom: 16px;
  position: relative;
}
.product_info_section .product_info_tabs .tab_triggers .trigger span {
  margin-left: 16px;
  border-radius: 4px;
  background-color: #3c4242;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 6px;
}
.product_info_section .product_info_tabs .tab_triggers .trigger::before {
  content: "";
  width: 0px;
  opacity: 0;
  visibility: 0;
  height: 1.5px;
  background-color: #000000;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 250ms;
}
.product_info_section .product_info_tabs .tab_triggers .trigger.active::before {
  width: 40px;
  opacity: 1;
  visibility: visible;
}
.product_info_section .product_info_tabs .tab_triggers .trigger,
.product_info_section .product_info_tabs .tab_triggers .trigger * {
  cursor: pointer;
}
.product_info_section .product_info_tabs .tab_contents .content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms;
}
.product_info_section .product_info_tabs .tab_contents .content.active {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 250ms;
}
.product_info_section .product_info_tabs .description_content .description {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.32px;
  margin-bottom: 30px;
}
.product_info_section .product_info_tabs .description_content .title {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table,
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table tr,
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table td {
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  border-collapse: collapse;
}
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table tr td {
  padding: 16px 24px;
}
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table tr td h3 {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-bottom: 12px;
}
.product_info_section .product_info_tabs .description_content .table_wrapper .specification_table tr td h2 {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.32px;
}
.product_info_section .product_info_tabs .faq_content {
  margin-bottom: 12px;
}
.product_info_section .product_info_tabs .faq_content .faq {
  margin-bottom: 20px;
}
.product_info_section .product_info_tabs .faq_content .faq .question {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product_info_section .product_info_tabs .faq_content .faq .answere {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.32px;
}
.product_info_section .product_media_tabs .tab_triggers {
  display: flex;
  margin-bottom: 30px;
}
.product_info_section .product_media_tabs .tab_triggers .trigger {
  width: 50%;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 16px;
  text-align: center;
}
.product_info_section .product_media_tabs .tab_triggers .trigger,
.product_info_section .product_media_tabs .tab_triggers .trigger * {
  cursor: pointer;
}
.product_info_section .product_media_tabs .tab_triggers .trigger.active {
  border-bottom: 2px solid #4b4e67;
}
.product_info_section .product_media_tabs .tab_contents .content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms;
}
.product_info_section .product_media_tabs .tab_contents .content.active {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 250ms;
}
.product_info_section .product_media_tabs .video_content {
  width: 100%;
  position: relative;
  padding: 5px;
}
.product_info_section .product_media_tabs .video_content video {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.product_info_section .product_media_tabs .video_content .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.product_info_section .product_media_tabs .video_content .thumbnail .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(60, 66, 66, 0.2509803922);
  position: absolute;
  left: 0;
  top: 0;
}
.product_info_section .product_media_tabs .video_content .thumbnail .play_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.product_info_section .product_media_tabs .video_content .thumbnail .play_btn,
.product_info_section .product_media_tabs .video_content .thumbnail .play_btn * {
  cursor: pointer;
}
.product_info_section .product_media_tabs .video_content .thumbnail .video_title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.44px;
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}
.product_info_section .product_media_tabs .video_content .thumbnail .video_length {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  position: absolute;
  top: 20px;
  right: 20px;
}

/* css-side-effects-plugin-ns:node_modules/lightbox.js-react/dist/index.css */
._30i9K {
  margin: 0;
  padding: 0;
}
._30i9K * {
  box-sizing: border-box;
}
._3kSzL {
  background-color: black;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
._tCjNO {
  padding-bottom: 2px;
}
._2IJEr:hover,
._2IJEr:focus,
._2Yv4j svg:hover,
._2Yv4j svg:focus,
._1lgEi span:hover,
._DDceb:hover,
._2Vept:hover {
  text-decoration: none;
  cursor: pointer;
}
._2gxOL:hover,
._3gp2w:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(230, 230, 230);
}
._2crlL {
  display: flex;
}
._34QvR {
  display: none;
}
._1R214 {
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}
img._3EuZy {
  transition: 0.3s;
}
._3EuZy:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
._DDceb {
  right: 1%;
  padding-left: 2vw;
  padding-right: 3vw;
}
._1lgEi {
  left: 2%;
  padding-right: 2vw;
}
._3pfLz {
  transition: border 0.3s;
}
._1R214 {
  display: inline;
}
._Fp_rP {
  width: 90%;
}
._1jASN {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media screen and (min-width: 20em) {
  ._22ETc {
    height: 67vw;
    width: 60%;
  }
  ._-wN_b {
    position: fixed;
    bottom: 5%;
    width: 100vw;
  }
  ._2b4P7 ._1yWyS {
    height: 100vh;
  }
  ._2b4P7 ._1JRT_ {
    top: 0;
  }
  ._1R214 {
    width: 80px;
    max-width: 80px;
    height: 7vh;
  }
  ._KbOLW {
    bottom: 2%;
  }
  ._1j-pZ {
    padding-bottom: 16px;
    width: 100vw;
  }
  ._3pfLz {
    margin-left: auto;
    margin-right: auto;
  }
  ._DDceb {
    right: 0.9%;
  }
  ._1lgEi {
    left: 1.5%;
  }
}
._1R214 {
  height: 5vh;
  border-radius: 6px;
  margin-right: 7px;
  -o-object-fit: cover;
  object-fit: cover;
}
._-wN_b {
  position: absolute;
  bottom: 10%;
  height: 5vh;
  width: 100vw;
  z-index: 10000000;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
._1Odla {
  width: 78vw;
}
._KbOLW {
  bottom: 5%;
}
._1nxK_ {
  height: 19vh;
}
._z-jNd video {
  height: 100%;
  max-height: 70vh;
  width: 100%;
}
._2jJU2 {
  position: absolute;
  bottom: 14%;
  width: 100vw;
  z-index: 10000000;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
._lY1R_ {
  cursor: pointer;
  margin: 0.2em;
}
._PH6f8 {
  width: 99vw;
  height: 100%;
  max-height: 100%;
  direction: ltr;
  overflow: hidden;
  touch-action: none;
  padding: 0;
  overflow-y: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
}
._wTR3b {
  height: 100%;
  max-height: 100%;
  direction: ltr;
  overflow: hidden;
  touch-action: none;
  padding: 0;
  overflow-y: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000000;
}
._11N04 img {
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}
._20Hp6 img {
  height: 80vh;
  max-width: 100%;
  max-height: 100%;
}
._20Hp6 {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1000000;
}
._3PObS {
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
}
._1JRT_ {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: top 0.2s ease-in-out 0s;
  top: 10%;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1000000;
}
._29Kdn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}
._1ni2w {
  top: 6%;
}
._GV0NM {
  top: 12%;
}
._2b4P7 {
  top: 0;
}
._11N04 {
  top: 0;
}
._3BNPD {
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
._DDceb,
._1lgEi {
  top: calc(50% - 20px);
  position: absolute;
  display: flex;
  transition: all 0.2s;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  z-index: 10000000;
}
._DDceb:hover,
._1lgEi:hover {
  color: rgb(230, 230, 230);
}
._j1nqC {
  position: absolute;
  max-width: 100vw;
}
._ABXHD {
  display: flex;
  flex-direction: row;
  align-items: center;
}
._2Yv4j {
  color: white;
  position: absolute;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  top: 10px;
  right: 45px;
  z-index: 999999999;
}
._2eq8V {
  aspect-ratio: 16 / 9;
  height: 65vh;
  width: 100%;
}
._1P3Wc {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
  max-height: 65vh;
}
._z-jNd {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
._Aq8HC,
._w68I8 {
  margin-left: auto;
  margin-right: auto;
}
._qtY0U {
  position: fixed;
  top: 4%;
  right: 3%;
}
._3cxVN ._2Yv4j svg {
  transition: 0.3s all;
}
._2Yv4j svg:active {
  transform: scale(0.97);
}
._2pA2G {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
._2Yv4j svg {
  margin-left: 0.6em;
  padding: 0.2em;
  transition: all 0.1s;
  line-height: 16px;
  vertical-align: baseline;
}
._2Vept {
}
@media screen and (min-width: 6em) {
  ._2Yv4j {
    top: 3%;
  }
  ._wTR3b {
    width: 100vw;
  }
  ._2Yv4j svg {
    padding-right: 0.3em;
  }
  ._30i9K img {
    cursor: pointer;
  }
  ._1R214 {
    height: 9vh;
  }
  ._DDceb {
    top: calc(48% - 20px);
  }
  ._1lgEi {
    top: calc(48% - 20px);
  }
}
@media screen and (min-width: 39em) {
  ._wTR3b {
    width: 100vw;
  }
  ._1JRT_ {
    width: 100vw;
    height: 95vh;
    position: absolute;
  }
  ._1j-pZ {
    padding-bottom: 0px;
  }
  ._DDceb {
    right: 0.7%;
  }
  ._11N04 {
    width: 100vw;
    height: 100vh;
  }
  ._11N04 img {
    height: 100vh;
  }
  ._3pfLz {
    margin-top: 0.6em;
  }
  ._1R214 {
    width: 80px;
    max-width: 80px;
  }
  ._2Yv4j svg {
  }
  ._1lgEi {
    left: 2.7%;
    padding-right: 2vw;
  }
}
@media screen and (min-width: 42em) {
  ._22ETc {
    height: 70vh;
    width: 80vw;
  }
  ._2yYOi {
    display: grid;
    width: 92vw;
    place-items: start;
    margin-left: auto;
    margin-right: auto;
    scrollbar-gutter: stable both-edges;
  }
  ._1JRT_ img {
    margin-top: 0vh;
  }
  ._11N04 img {
    width: 100vw;
  }
  ._hWNoE {
    top: 0%;
  }
  ._1ek8k {
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }
  ._hWNoE {
    height: 100vh;
    width: 100vw;
    position: absolute;
  }
  ._hWNoE img {
    -o-object-fit: contain;
    object-fit: contain;
  }
  ._3pfLz {
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-top: 1.1em;
  }
  ._3pfLz::-webkit-scrollbar {
    display: none;
  }
  ._2P48G {
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }
  ._-wN_b {
    margin-top: 0.1em;
    padding-top: 0.3em;
    height: 5vh;
    bottom: 11%;
  }
  ._1R214,
  ._1xBL9 img {
    height: 10vh;
  }
  ._wTR3b {
    width: 100vw;
  }
  ._1JRT_ {
    width: 100vw;
    height: 95vh;
  }
  ._2b4P7 {
    height: 100vh;
  }
  ._11N04 {
    width: 100vw;
    height: 100vh;
  }
  ._2Yv4j svg {
  }
  ._1lgEi {
    left: 2%;
    padding-right: 2vw;
  }
  ._DDceb {
    right: 0.4%;
  }
  ._15MH_ {
    width: 78vw;
  }
  ._3JYCS {
    left: 1%;
  }
  ._1jASN {
    gap: 8px;
  }
}
@media screen and (min-width: 53em) {
  ._1yWyS {
    display: grid;
    width: 100vw;
    height: 70vh;
    place-items: start;
  }
  ._2b4P7 ._1yWyS,
  ._31Tcx ._1yWyS {
    padding-top: 12vh;
  }
  ._3A0RO {
  }
  ._1R214 {
    width: 95px;
    max-width: 80px;
  }
  ._15MH_ {
    width: 78vw;
  }
}
@media screen and (min-width: 80em) {
  ._1JRT_ img {
  }
  ._11N04 img {
    width: 100vw;
  }
}
._1kIQ4,
._1kIQ4 svg {
  background-color: white;
  color: black;
}
._1kIQ4:hover,
._1kIQ4 svg:hover {
  background-color: white;
  color: black;
}
._1qbJe,
._1qbJe svg {
  background-color: transparent;
  color: silver;
}
._1qbJe:hover,
._1qbJe svg:hover {
  color: rgba(107, 107, 107, 0.8);
}
._3DIHj svg {
  background-color: white;
  color: black;
}
._3DIHj svg:hover {
  background-color: white;
  color: black;
  color: rgb(24, 24, 24, 0.8);
}
._11aT5 svg {
  background-color: transparent;
  color: #626b77;
}
._11aT5 svg:hover {
  color: rgba(99, 99, 99, 0.8);
}
._mdJQi {
  -o-object-fit: contain;
  object-fit: contain;
}
._3pfLz ._3sodH {
  border: solid rgb(107, 133, 206) 2px;
}
._3bkUp {
  border: solid transparent 2px;
}
._3pfLz {
  width: 95%;
}
._32vVi {
  --thumbs-slide-spacing: 2px;
  margin-top: var(--thumbs-slide-spacing);
}
@media screen and (max-width: 29em) {
  ._11N04 img {
    -o-object-fit: cover;
    object-fit: cover;
  }
  ._32vVi {
    --thumbs-slide-spacing: 3px;
    margin-top: var(--thumbs-slide-spacing);
  }
  ._sqvn- {
    margin-top: 5vh;
  }
  ._3pfLz {
    width: 98%;
  }
  ._1R214 {
    width: 80px;
    max-width: 80px;
  }
  ._z-jNd video {
    max-height: 65vh;
  }
  ._qtY0U {
    position: fixed;
    top: 2%;
    right: 15px;
  }
  ._1P3Wc {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-height: 65vh;
  }
  ._z-jNd {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }
  ._2Yv4j {
    position: fixed;
    left: 115px;
  }
  ._1ni2w {
    top: 3%;
  }
  ._DDceb,
  ._1lgEi {
    padding-bottom: 2vh;
    padding-top: 2vh;
  }
  ._DDceb {
    padding-left: 4vw;
  }
  ._1lgEi {
    padding-right: 4vw;
  }
  ._2Yv4j {
  }
}
@media screen and (max-width: 53em) {
  ._1yWyS {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 70vh;
  }
  ._1yWyS img {
    margin-left: auto;
    margin-right: auto;
  }
  ._2yYOi {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 70vh;
  }
  ._hWNoE {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: top 0.2s ease-in-out 0s;
    top: 10%;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1000000;
  }
  ._21mY1 {
    -o-object-fit: contain;
    object-fit: contain;
  }
}
._11N04 img {
  -o-object-fit: cover;
  object-fit: cover;
}
._3pfLz {
  text-align: center;
  white-space: nowrap;
}
._3Id1- {
  margin-left: auto;
  margin-right: auto;
}
._mdJQi {
  margin-left: auto;
  margin-right: auto;
}
._3qqrl {
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  animation: _1hUzk 1s linear infinite;
}
@keyframes _1hUzk {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
._1itZw {
  border: 5px solid #626b77;
  border-bottom-color: transparent;
}
._2TI_i {
  border: 5px solid #fff;
  border-bottom-color: transparent;
}
._3A0RO {
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
}
._ABXHD button,
._2kumA,
._3aFDN {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
}
._ABXHD svg {
  box-sizing: border-box;
}
._2kumA svg,
._3aFDN svg {
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 0.5px;
}
._2kumA {
  padding-top: 3px;
}
._3aFDN {
  cursor: pointer;
}
._3aFDN svg {
  margin: 0;
  padding: 5px;
  background-color: black;
  border-color: transparent;
  border-radius: 28px;
}
._1nxK_ {
  bottom: 0%;
  height: 19vh;
  margin-top: 0em;
  width: 100vw;
}
._Fp_rP {
  margin-top: 0em;
}
._238y5 {
  top: 7%;
}
._238y5._2b4P7 {
  top: 0;
}
._wTR3b {
  -ms-overflow-style: none;
}
._3pfLz::-webkit-scrollbar {
  display: none;
}
._2FvXV {
  border-radius: 20px;
}
._oa5b0 {
  max-width: 100%;
}
._z-bHV {
  max-width: 80%;
}
@media screen and (max-width: 880px) {
  ._2Yv4j {
    position: fixed;
    right: 5%;
  }
}
@media screen and (max-width: 480px) {
  ._2Yv4j {
    position: fixed;
    left: 180px;
  }
}
@media screen and (max-width: 440px) {
  ._2Yv4j {
    position: fixed;
    left: 160px;
  }
}
@media screen and (max-width: 410px) {
  ._2Yv4j {
    position: fixed;
    left: 140px;
  }
}
@media screen and (max-width: 385px) {
  ._2Yv4j {
    position: fixed;
    left: 135px;
  }
}
@media screen and (max-width: 365px) {
  ._2Yv4j {
    position: fixed;
    left: 90px;
  }
}
@media screen and (max-width: 345px) {
  ._2Yv4j {
    position: fixed;
    left: 82px;
  }
}
@media screen and (max-width: 325px) {
  ._2Yv4j {
    position: fixed;
    left: 60px;
  }
}
@media screen and (max-width: 300px) {
  ._2Yv4j {
    position: fixed;
    left: 30px;
  }
}
._hGbhv {
  margin-left: auto;
  margin-right: auto;
}
[class^=number-slide],
[class*=" number-slide"] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}
._1dVqh {
  position: relative;
}
._3MuXE {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}
._3U2py {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}
._3U2py:focus {
  outline: none;
}
._3U2py._3sodH {
  background: #000;
}
._35l6Q {
  position: absolute;
  top: 50%;
  font-size: 24px;
  font-weight: bold;
  transition: all 0.2s;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: sans-serif;
}
._1I4Zg {
  left: 8px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
._1lqBv {
  left: auto;
  right: 8px;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}
._35l6Q:hover {
  color: rgb(230, 230, 230);
}
._37Xda,
._37Xda:hover {
  color: rgba(255, 255, 255, 0.5);
}
._1LtID {
  margin-top: 12px;
}
._2y9OU {
  border: "solid transparent 2px";
}
._2oAIc {
  width: 100%;
  height: 100%;
}
._2oAIc iframe {
  width: 100%;
  height: 100%;
}
._2-c5L {
  height: 100%;
}
._30i9K iframe {
  margin: none;
  padding: none;
  border: none;
}
._1Bey- {
  z-index: 100000;
  background-color: transparent;
  border: none;
}
._3pfLz {
  margin-left: auto;
  margin-right: auto;
}
._3xuz3 {
  cursor: pointer;
}
._1fpgA > * {
  color: inherit;
  fill: inherit;
}
._1cifY {
  margin-left: auto;
  margin-right: auto;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
}
._1mBUR {
  overflow: hidden;
  width: 100vw;
}
._Bqct9 {
  backface-visibility: hidden;
  touch-action: pan-y;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
}
._39UN- {
  width: 78vw;
  max-width: 78vw;
}
._lqLcV ._1eJNe {
  flex: 0 0 80%;
  min-width: 0;
  max-width: 100%;
  padding-left: 12px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
._1tmVU {
  overflow: hidden;
}
._3-Y1e {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
  width: 100%;
}
._3-Y1e::before,
._3-Y1e::after {
  content: "";
  margin: auto;
}
._jp0uN {
  padding-left: var(--thumbs-slide-spacing);
}
._3S-ie {
  height: var(--thumbs-slide-height);
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
._1afvv {
  margin-left: auto;
}
._1HGLk {
  color: white;
}
._1TshF {
  display: flex;
}
._1HGLk {
  text-align: left;
  min-width: 17vw;
  max-width: 17vw;
  padding-top: 5vw;
}
._U_FmQ {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
._1Y4P9 {
  padding-left: 7vw;
}
._1Y4P9 span {
  display: block;
}
._1vmhr {
  position: absolute;
  top: 1%;
  right: 2%;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}
._3djxx {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}
._2UUxD {
  display: flex;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}
._2UUxD img {
  pointer-events: none;
}
._gMqOZ {
  height: 10px;
  width: 10px;
  background-color: silver;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
._1KxdK {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}
._1mIvU._Bqct9 {
  transform: none !important;
  display: flex;
  height: 250px;
}
._1mIvU._1eJNe {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0 !important;
  right: 0 !important;
  opacity: 0;
  transition: opacity 0.5s;
  counter-increment: embla;
  border-radius: 0.5rem;
}
._1mIvU._1eIZ- {
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 1;
}
._1mIvU._1eJNe:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/product-slider/productslider.css */
.product_slider_section {
  padding: 45px 0;
}
.product_slider_section .cm_title_box {
  margin-bottom: 40px;
}
.product_slider_section .product_slider .swiper-slide {
  padding: 0 15px;
}

/* css-side-effects-plugin-ns:app/assets/custom.css */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  src: url(/build/_assets/ProductSans-Regular-ZAITCUTY.woff) format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 100;
  src: url(/build/_assets/ProductSans-Thin-5KXYHX33.woff) format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 300;
  src: url(/build/_assets/ProductSans-Light-OHVVDNEB.woff) format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  src: url(/build/_assets/ProductSans-Medium-BZF6ZEB3.woff) format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 600;
  src: url(/build/_assets/ProductSans-Black-ZXFKNSEB.woff) format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  src: url(/build/_assets/ProductSans-Bold-XF4DDR77.woff) format("woff");
}
.form-control:focus {
  border-color: var( --kt-primary, #3699ff ) !important;
  box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25);
}
.imgbox_full,
.imgbox {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.imgbox_full img,
.imgbox img {
  width: 100%;
  height: auto;
}
.imgbox_full {
  height: 100%;
}
.imgbox_full img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
body {
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  color: #000;
  position: static;
  scroll-behavior: smooth;
}
.main_content {
  min-height: 60vh;
  display: contents;
}
select,
input,
button:focus {
  outline: none;
}
ul:not(.jodit-react-container ul):not(.rich_text ul),
ol:not(.jodit-react-container ol):not(.rich_text ol) {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
  color: #000;
}
.menu {
  display: block;
}
.cm_title_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
  padding: 10px 0;
}
.cm_title_box .title_box {
  padding-left: 30px;
  position: relative;
}
.cm_title_box .title_box h2 {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
.cm_title_box .title_box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 20px;
  border-radius: 8px;
  background-color: #4b4e67;
}
.cm_title_box .shop_all {
  display: block;
  border-radius: 1000px;
  border: 1px solid #4b4e67;
  background-color: #fff;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 24px;
  transition: all 250ms;
}
.cm_title_box .shop_all:hover {
  background-color: #4b4e67;
  color: #ffffff;
}
.cm_title_box .shop_all,
.cm_title_box .shop_all * {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .cm_title_box .shop_all {
    padding: 4px 12px;
  }
}
.product_card {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
}
.product_card .product_image,
.product_card .product_details,
.product_card .product_tags {
  width: calc(33.3333333333% - 10.6666666667px);
}
.product_card .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.product_card .product_image .imgbox {
  max-width: 300px;
  margin: 0 auto;
}
.product_card .product_details .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 11px 0;
}
.product_card .product_details .product_price .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product_card .product_details .product_price .main_price .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.product_card .product_details .product_price .main_price .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.product_card .product_details .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .unit_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
}
.product_card .product_details .unit_box .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .unit_box .unit {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .list_box {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.product_card .product_details .list_box .list_content {
  width: calc(50% - 4px);
}
.product_card .product_details .list_box .list_content.list_contact {
  width: 100%;
}
.product_card .product_details .list_box .list_content.list_contact.half_width {
  width: calc(50% - 4px);
}
.product_card .product_details .list_box .list_content .title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
.product_card .product_details .list_box .list_content .list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  overflow-x: hidden;
}
.product_card .product_details .list_box .list_content .list li .icon_box {
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.product_card .product_details .list_box .list_content .list li .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_card .product_details .list_box .list_content .list li .text_box,
.product_card .product_details .list_box .list_content .list li .text_box * {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .list_box .list_content .list li .text_box a,
.product_card .product_details .list_box .list_content .list li .text_box a * {
  cursor: pointer;
}
.product_card .product_tags .tag_line {
  color: #959595;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.product_card .product_tags .tags_content {
  margin: 8px 0 12px;
}
.product_card .product_tags .tags_content .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.product_card .product_tags .tags_content .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #4b4e67;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product_card .product_tags .button_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 12px;
}
.product_card .product_tags .button_box .follow_btn,
.product_card .product_tags .button_box .chat_btn {
  width: calc(50% - 8px);
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background: #fff;
  padding: 1.5px 12px;
  color: rgba(0, 0, 0, 0.3137254902);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
}
.product_card .product_tags .button_box .follow_btn::before,
.product_card .product_tags .button_box .chat_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.product_card .product_tags .button_box .follow_btn:hover::before,
.product_card .product_tags .button_box .chat_btn:hover::before {
  width: 100%;
}
.product_card .product_tags .button_box .follow_btn,
.product_card .product_tags .button_box .follow_btn *,
.product_card .product_tags .button_box .chat_btn,
.product_card .product_tags .button_box .chat_btn * {
  cursor: pointer;
}
.product_card .product_tags .button_box .follow_btn .icon_box,
.product_card .product_tags .button_box .chat_btn .icon_box {
  width: 20px;
  height: 20px;
  padding: 2.464px 1.267px 2.466px 1.749px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_card .product_tags .button_box .follow_btn .icon_box svg,
.product_card .product_tags .button_box .chat_btn .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_card .product_tags .button_box .connect_btn {
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #4b4e67;
  padding: 6px 24px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.product_card .product_tags .button_box .connect_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.product_card .product_tags .button_box .connect_btn:hover::before {
  width: 100%;
}
.product_card .product_tags .button_box .connect_btn,
.product_card .product_tags .button_box .connect_btn * {
  cursor: pointer;
}
@media only screen and (max-width: 1200px) {
  .product_card .product_details,
  .product_card .product_tags {
    width: calc(50% - 8px);
  }
  .product_card .product_image {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .product_card {
    padding: 12px;
  }
  .product_card .product_details,
  .product_card .product_tags {
    width: 100%;
  }
  .product_card .product_image {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .product_card .product_details .list_box .list_content {
    width: 100%;
  }
}
.sm_product_card .head {
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 35px 40px;
  margin-bottom: 16px;
}
.sm_product_card .head .imgbox_full {
  aspect-ratio: 1/1;
}
.sm_product_card .head .offer_badge {
  border-radius: 4px;
  background-color: #4b4e67;
  padding: 4px 12px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  left: 12px;
  top: 12px;
}
.sm_product_card .head .button_box {
  position: absolute;
  top: 12px;
  right: 12px;
}
.sm_product_card .head .button_box button {
  padding: 8px;
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  margin-bottom: 8px;
}
.sm_product_card .head .button_box button,
.sm_product_card .head .button_box button * {
  cursor: pointer;
}
.sm_product_card .head .button_box button svg {
  width: 100%;
  height: 100%;
}
.sm_product_card .body {
  padding: 0 0 20px 0;
}
.sm_product_card .body .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sm_product_card .body .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 8px 0 12px;
}
.sm_product_card .body .product_price .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sm_product_card .body .product_price .main_price .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sm_product_card .body .product_price .main_price .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.sm_product_card .body .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.sm_product_card .body .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.sm_product_card .body .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #808080;
  color: #808080;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1882352941);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all linear 0.1s;
}
.modal .modal_inner {
  width: 760px;
  height: 500px;
  overflow-y: scroll;
  background-color: #ffffff;
  padding: 10px 10px 10px 20px;
  border-radius: 6px;
  margin-top: 20px;
  transition: all linear 0.3s;
  transition-delay: 0.1s;
}
.modal .modal_inner::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.modal .modal_inner::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2509803922);
}
.modal .modal_inner .top_bar {
  display: flex;
  justify-content: space-between;
}
.modal .modal_inner .top_bar .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
}
.modal .modal_inner .top_bar .close {
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
}
.modal .modal_inner .top_bar .close svg {
  width: 100%;
  height: 100%;
}
.modal .modal_inner .top_bar .close,
.modal .modal_inner .top_bar .close * {
  cursor: pointer;
}
.modal .modal_inner .top_bar .close:hover {
  background-color: rgba(0, 0, 0, 0.1254901961);
}
.modal .modal_content video {
  width: 100%;
  height: auto;
}
.modal.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.modal.active .modal_inner {
  margin-top: 0px;
}
.search_result_wrapper {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  width: 100%;
  height: auto;
  transition: all ease 350ms;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.search_result_wrapper .search_list li {
  margin-bottom: 10px;
}
.search_result_wrapper .search_list li a {
  font-size: 18px;
  color: #000;
}
.search_result_wrapper .search_list li a,
.search_result_wrapper .search_list li a * {
  cursor: pointer;
}
.search_result_wrapper.active {
  opacity: 1;
  visibility: visible;
}
.progress_wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #111111;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  transition: all 200ms linear;
}
.progress_wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress_wrap svg path {
  fill: none;
}
.progress_wrap svg.progress-circle path {
  stroke: #ffffff;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear;
}
.progress_wrap .chev {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
  stroke: #ffffff;
  transition: all ease-in-out 350ms;
}
.progress_wrap .chev path {
  fill: #ffffff;
  stroke: #ffffff;
}
.progress_wrap.active-progress .chev {
  top: 50%;
}
.custom-separator {
  border-bottom: 1px solid #e0e3f1;
  margin: 10px;
}
.custom-menu-link {
  color: #111111;
}
.chart-container {
  position: relative;
  margin: auto;
  height: 300px;
  width: 100%;
}
.jodit-status-bar-link {
  display: none;
}
.hover-item {
  cursor: pointer;
  padding: 2px;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 4px;
}
.hover-item:hover {
  background-color: #f0f0f0;
  color: #333;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/career-info/career_info.css */
.career_info_section {
  margin-top: 80px;
}
.career_banner_section {
  padding: 100px 0;
  background-color: rgba(75, 78, 103, 0.062745098);
}
.headline {
  font-size: 3rem;
  font-weight: bold;
}
.headline_1 {
  font-size: 2.5rem;
  font-weight: bold;
}
.headline_2 {
  font-size: 2rem;
  font-weight: bold;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/contact-banner/contactbanner.css */
.contact_banner_section {
  margin-top: 80px;
  padding: 90px 0;
  background-color: rgba(75, 78, 103, 0.062745098);
}
.contact_banner_section .tag {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  border-radius: 128px;
  background: rgba(75, 78, 103, 0.4);
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 24px;
  margin: 0 auto 16px;
}
.contact_banner_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  max-width: 660px;
  margin: 0 auto 24px;
}
.contact_banner_section .paragraph {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.1px;
  max-width: 640px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .contact_banner_section .tag {
    font-size: 10px;
  }
  .contact_banner_section .heading {
    font-size: 24px;
    line-height: 30px;
  }
  .contact_banner_section .paragraph {
    font-size: 14px;
    line-height: 18px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/contact-form/contactform.css */
.contact_form_section {
  padding: 90px 0;
}
.contact_form_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
  margin-bottom: 16px;
}
.contact_form_section .paragraph {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 800px;
  margin: 0 auto 60px;
}
.contact_form_section .form_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
}
.contact_form_section .form_wrapper .input_box {
  width: 100%;
}
.contact_form_section .form_wrapper .input_box label {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.1px;
  margin-bottom: 8px;
}
.contact_form_section .form_wrapper .input_box input,
.contact_form_section .form_wrapper .input_box textarea,
.contact_form_section .form_wrapper .input_box select {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #e5e5e7;
  background: #f8f9fb;
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
}
input:focus,
select:focus,
button:focus {
  border-color: #007bff;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}
.contact_form_section .form_wrapper .input_box input,
.contact_form_section .form_wrapper .input_box input *,
.contact_form_section .form_wrapper .input_box textarea,
.contact_form_section .form_wrapper .input_box textarea *,
.contact_form_section .form_wrapper .input_box select,
.contact_form_section .form_wrapper .input_box select * {
  cursor: text;
}
.contact_form_section .form_wrapper .input_box input::-webkit-input-placeholder,
.contact_form_section .form_wrapper .input_box textarea::-webkit-input-placeholder,
.contact_form_section .form_wrapper .input_box select::-webkit-input-placeholder {
  color: #858c95;
}
.contact_form_section .form_wrapper .input_box textarea {
  min-height: 140px;
}
.contact_form_section .form_wrapper .input_box.half_width {
  width: calc(50% - 12px);
}
.contact_form_section .form_wrapper .check_box {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}
.contact_form_section .form_wrapper .check_box input,
.contact_form_section .form_wrapper .check_box input * {
  cursor: pointer;
}
.contact_form_section .form_wrapper .check_box .text {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.16px;
}
.contact_form_section .form_wrapper .submit_btn {
  border-radius: 6px;
  background: #4b4e67;
  border: none;
  padding: 12px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.contact_form_section .form_wrapper .submit_btn,
.contact_form_section .form_wrapper .submit_btn * {
  cursor: pointer;
}
.contact_form_section .info_wrapper {
  padding: 0 30px;
}
.contact_form_section .info_wrapper .heading {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.18px;
  margin-bottom: 16px;
  text-align: left;
}
.contact_form_section .info_wrapper .para {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
}
.contact_form_section .info_wrapper .link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0b124c;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.contact_form_section .info_wrapper .link,
.contact_form_section .info_wrapper .link * {
  cursor: pointer;
}
.contact_form_section .info_wrapper .link .icon_box {
  width: 20px;
  height: 30px;
}
.contact_form_section .info_wrapper .link .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.contact_form_section .info_wrapper .get_in_touch {
  margin-bottom: 32px;
}
.contact_form_section .info_wrapper .address {
  margin-bottom: 32px;
}
.contact_form_section .info_wrapper .social_media .links {
  display: flex;
  align-items: center;
  gap: 15px;
}
.contact_form_section .info_wrapper .social_media .links li {
  width: 24px;
  height: 24px;
}
.contact_form_section .info_wrapper .social_media .links li,
.contact_form_section .info_wrapper .social_media .links li * {
  cursor: pointer;
}
.contact_form_section .info_wrapper .social_media .links li svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
@media only screen and (max-width: 992px) {
  .contact_form_section .form_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
  }
  .contact_form_section .form_wrapper .input_box {
    width: 100%;
  }
  .contact_form_section .form_wrapper .input_box label {
    color: #323539;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.1px;
    margin-bottom: 8px;
  }
  .contact_form_section .form_wrapper .input_box input,
  .contact_form_section .form_wrapper .input_box textarea,
  .contact_form_section .form_wrapper .input_box select {
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #e5e5e7;
    background: #f8f9fb;
    color: #858c95;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    width: 100%;
  }
  .contact_form_section .form_wrapper .input_box input::-webkit-input-placeholder,
  .contact_form_section .form_wrapper .input_box textarea::-webkit-input-placeholder,
  .contact_form_section .form_wrapper .input_box select::-webkit-input-placeholder {
    color: #858c95;
  }
  .contact_form_section .form_wrapper .input_box textarea {
    min-height: 140px;
  }
  .contact_form_section .form_wrapper .input_box.half_width {
    width: calc(50% - 12px);
  }
  .contact_form_section .info_wrapper {
    padding: 0px;
  }
}
@media only screen and (max-width: 768px) {
  .contact_form_section .form_wrapper .input_box.half_width {
    width: 100%;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/map/map.css */
.get_in_touch_section {
  padding: 60px 0;
}
.get_in_touch_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
  margin-bottom: 16px;
}
.get_in_touch_section .paragraph {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 800px;
  margin: 0 auto 60px;
}
.get_in_touch_section .map_wrapper {
  border-radius: 12px;
  border: 1px solid #e5e5e7;
  overflow: hidden;
  display: flex;
  height: 100%;
  min-height: 350px;
}
.get_in_touch_section .map_wrapper div {
  display: flex;
}
.get_in_touch_section .info_wrapper {
  padding: 0 30px;
}
.get_in_touch_section .info_wrapper .heading {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.18px;
  margin-bottom: 16px;
  text-align: left;
}
.get_in_touch_section .info_wrapper .para {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 8px;
}
.get_in_touch_section .info_wrapper .link {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #0b124c;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}
.get_in_touch_section .info_wrapper .link,
.get_in_touch_section .info_wrapper .link * {
  cursor: pointer;
}
.get_in_touch_section .info_wrapper .link .icon_box {
  width: 20px;
  height: 30px;
}
.get_in_touch_section .info_wrapper .link .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.get_in_touch_section .info_wrapper .get_in_touch {
  margin-bottom: 32px;
}
.get_in_touch_section .info_wrapper .address {
  margin-bottom: 32px;
}
.get_in_touch_section .info_wrapper .social_media .links {
  display: flex;
  align-items: center;
  gap: 15px;
}
.get_in_touch_section .info_wrapper .social_media .links li {
  width: 24px;
  height: 24px;
}
.get_in_touch_section .info_wrapper .social_media .links li,
.get_in_touch_section .info_wrapper .social_media .links li * {
  cursor: pointer;
}
.get_in_touch_section .info_wrapper .social_media .links li svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
@media only screen and (max-width: 992px) {
  .get_in_touch_section .info_wrapper {
    padding: 0;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/terms-banner/termsbanner.css */
.terms_banner_section {
  margin-top: 80px;
  padding: 80px 0;
  background-color: #e5e5e7;
}
.terms_banner_section .badge {
  border-radius: 128px;
  background: rgba(75, 78, 103, 0.4);
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 12px;
}
.terms_banner_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  margin-bottom: 24px;
}
.terms_banner_section .pera {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 640px;
  margin: 0 auto 36px;
}
.terms_banner_section .search_box {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #e5e5e7;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 8px 8px 8px 16px;
  max-width: 640px;
  margin: 0 auto;
}
.terms_banner_section .search_box .search_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.terms_banner_section .search_box .search_icon svg {
  width: 100%;
  height: 100%;
  margin-top: -6px;
}
.terms_banner_section .search_box .input_field {
  width: calc(100% - 30px - 30%);
  border: none;
  background-color: transparent;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.terms_banner_section .search_box .input_field::-webkit-input-placeholder {
  color: #858c95;
}
.terms_banner_section .search_box .submit_btn {
  width: 30%;
  border-radius: 6px;
  border: none;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 10px 16px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/terms-content/termscontent.css */
.terms_section {
  padding: 50px 0;
}
.terms_section .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.22px;
}
.terms_section .text_box .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}
.terms_section .text_box .list li {
  padding-left: 25px;
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 18px;
  position: relative;
}
.terms_section .text_box .list li::before {
  content: "";
  background-image: url(/build/_assets/Checkbox-WLAS4ASK.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  width: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/advertisement/advertisement.css */
.advertisement_section {
  padding: 60px 0;
  background-color: #f0f1f7;
}
.advertisement_section .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44.8px;
  margin-bottom: 32px;
}
.advertisement_section .advertise_card {
  border-radius: 32px;
  background-color: #fff;
  padding: 64px 50px;
}
.advertisement_section .advertise_card .imgbox {
  max-width: 250px;
  aspect-ratio: 1/1;
  margin: 0 auto;
}
.advertisement_section .advertise_card .body {
  margin-top: 16px;
}
.advertisement_section .advertise_card .body .subheading {
  color: #808183;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 16px;
}
.advertisement_section .advertise_card .body .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}
.advertisement_section .advertise_card .body .pera {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 24px;
}
.advertisement_section .advertise_card .body .list li {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
}
.advertisement_section .advertise_card .body .read_more {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background-color: #4b4e67;
  color: #fff;
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  overflow: hidden;
  display: block;
}
.advertisement_section .advertise_card .body .read_more::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.advertisement_section .advertise_card .body .read_more:hover::before {
  width: 100%;
}
.advertisement_section .advertise_card .body .read_more,
.advertisement_section .advertise_card .body .read_more * {
  cursor: pointer;
}
@media only screen and (max-width: 992px) {
  .advertisement_section .advertise_card {
    padding: 34px 20px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/feature-card/feature-card.css */
.feature_card_section {
  padding: 40px 0;
  background-color: #555872;
  background-image: url(/build/_assets/bg-J3VUDSY3.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.feature_card_section .feature_card {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 40px;
}
.feature_card_section .feature_card .head {
  margin-bottom: 20px;
}
.feature_card_section .feature_card .head .icon_box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.12);
}
.feature_card_section .feature_card .body .title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}
.feature_card_section .feature_card .body .pera {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.feature_card_section .search_box {
  padding: 40px;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.feature_card_section .search_box_wrapper .input_box {
  width: 100%;
  border-radius: 1000px;
  background-color: #fff;
  display: flex;
  padding: 10px 15px 10px 15px;
  margin-bottom: 32px;
  position: relative;
}
.feature_card_section .search_box_wrapper .input_box input {
  border: none;
  color: #767676;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
  cursor: text;
}
.feature_card_section .search_box_wrapper .input_box button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 26px;
  background: #4b4e67;
  padding: 13px 20px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  overflow: hidden;
}
.feature_card_section .search_box_wrapper .input_box button::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.feature_card_section .search_box_wrapper .input_box button:hover::before {
  width: 100%;
}
.feature_card_section .search_box_wrapper .input_box button,
.feature_card_section .search_box_wrapper .input_box button * {
  cursor: pointer;
}
.feature_card_section .search_box_wrapper .input_box button .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.feature_card_section .search_box_wrapper .input_box button .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.feature_card_section .search_box_wrapper .tag_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 20px;
}
.feature_card_section .search_box_wrapper .tag_box .title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.feature_card_section .search_box_wrapper .tag_box .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 20px;
}
.feature_card_section .search_box_wrapper .tag_box .tags li a {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  border-radius: 22px;
  border: 1px solid #fff;
  padding: 10px 30px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .feature_card_section .search_box_wrapper .tag_box .tags li a {
    padding: 8px 20px;
    font-size: 12px;
  }
}
.feature_card_section .search_box_wrapper .tag_box .tags li a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 22px;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.feature_card_section .search_box_wrapper .tag_box .tags li a:hover::before {
  width: 100%;
}
.feature_card_section .search_box_wrapper .tag_box .tags li a {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .feature_card_section .search_box {
    padding: 20px;
    border-radius: 20px;
  }
  .feature_card_section {
    background-size: cover;
    padding: 30px 0;
  }
  .feature_card_section .search_box_wrapper .input_box {
    padding: 5px 15px;
  }
  .feature_card_section .search_box_wrapper .tag_box .tags li {
    padding: 10px 30px;
  }
  .feature_card_section .search_box_wrapper .input_box button .text {
    display: none;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/home-banner/home_banner.css */
.home_banner_section {
  margin-top: 80px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #4B4E67;
}
.home_banner_section .content_wrapper {
  padding: 60px 0;
}
.home_banner_section .content_wrapper .subheading {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 24px 0;
}
.home_banner_section .content_wrapper .subheading,
.home_banner_section .content_wrapper .subheading * {
  cursor: pointer;
}
.home_banner_section .content_wrapper .subheading .icon_box {
  width: 24px;
  height: 24px;
  display: flex;
}
.home_banner_section .content_wrapper .subheading .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.home_banner_section .content_wrapper .heading {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  max-width: 700px;
  margin-bottom: 32px;
}
.home_banner_section .content_wrapper .pera {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 700px;
  margin-bottom: 32px;
}
.home_banner_section .content_wrapper .button_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  row-gap: 25px;
}
.home_banner_section .content_wrapper .button_box .c_btn {
  padding: 15px 25px;
  border-radius: 3px;
  background: #fff;
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
}
.home_banner_section .content_wrapper .button_box .c_link {
  color: #fff;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
}
.home_banner_section .content_wrapper .button_box .c_btn,
.home_banner_section .content_wrapper .button_box .c_btn *,
.home_banner_section .content_wrapper .button_box .c_link,
.home_banner_section .content_wrapper .button_box .c_link * {
  cursor: pointer;
}
.home_banner_section .content_wrapper .imgbox {
  max-width: 400px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .home_banner_section {
    padding: 60px 0;
    background-size: cover;
  }
  .home_banner_section .content_wrapper {
    padding: 0px 0;
  }
  .home_banner_section .content_wrapper .subheading {
    font-size: 16px;
    line-height: 20px;
  }
  .home_banner_section .content_wrapper .heading {
    font-size: 22px;
    line-height: 25px;
  }
  .home_banner_section .content_wrapper .input_box button .text {
    display: none;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/image-with-text/imagewithlist.css */
.image_with_list_section {
  padding: 60px 0;
}
.image_with_list_section .heading {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.image_with_list_section .list .item {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  padding-bottom: 20px;
  padding-left: 10px;
  position: relative;
  width: 100%;
}
.image_with_list_section .list .item,
.image_with_list_section .list .item * {
  cursor: default;
}
.image_with_list_section .list .item .subtitle_box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.image_with_list_section .list .item .icon_box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 2px solid #d8d8d8;
  position: relative;
  overflow: visible;
  flex-shrink: 0;
}
.image_with_list_section .list .item .icon_box svg {
  width: 100%;
  height: 100%;
  fill: #4b4e67;
}
.image_with_list_section .list .item .text_box {
  max-width: calc(40%);
}
.image_with_list_section .list .item .subtitle {
  color: #4b4e67;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}
.image_with_list_section .list .item .title {
  color: #4b4e67;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px 0;
}
.image_with_list_section .list .item .pera {
  color: #767676;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  opacity: 1;
  visibility: visible;
  height: auto;
}
.image_with_list_section .list .item .imgbox {
  width: 45%;
  position: absolute;
  right: 0;
  border: 2px solid #4b4e67;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
}
.image_with_list_section .list .item,
.image_with_list_section .list .item * {
  transition: all linear 350ms;
}
.image_with_list_section .list .item.active .icon_box {
  border: 2px solid #4b4e67;
  background-color: #4b4e67;
}
.image_with_list_section .list .item.active .icon_box svg {
  fill: #fff;
}
.image_with_list_section .list .item.active .imgbox {
  top: 0;
  opacity: 1;
  visibility: visible;
}
.image_with_list_section .list .item:nth-last-child(1).active .imgbox {
  top: auto;
  bottom: 0;
}
.image_with_list_section .list .item:nth-last-child(2).active .imgbox {
  top: auto;
  bottom: 0;
}
.image_with_list_section .list .item:nth-last-child(3).active .imgbox {
  top: auto;
  bottom: 0;
}
@media only screen and (max-width: 992px) {
  .image_with_list_section .list .item .text_box {
    max-width: calc(55%);
  }
  .image_with_list_section .list .item .subtitle {
    font-size: 16px;
  }
  .image_with_list_section .list .item .title {
    font-size: 26px;
    line-height: 1;
  }
  .image_with_list_section .list .item .pera {
    font-size: 14px;
    line-height: 1;
  }
  .image_with_list_section .list .item .imgbox {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .image_with_list_section .heading {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .image_with_list_section .list .item {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .image_with_list_section .list .item .text_box {
    max-width: 100%;
    text-align: center;
  }
  .image_with_list_section .list .item .subtitle_box {
    justify-content: center;
  }
  .image_with_list_section .list .item .imgbox {
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transform: none;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/list-slider/listslider.css */
.list_and_slider_section {
  padding: 60px 0;
}
.list_and_slider_section .c_pera {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 60px;
}
.list_and_slider_section .list_box {
  margin-bottom: 32px;
}
.list_and_slider_section .list_box .list_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 16px;
  margin-bottom: 32px;
}
.list_and_slider_section .list_box .list_card .text_box .title {
  color: #0b0b0b;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}
.list_and_slider_section .list_box .list_card .text_box .pera {
  color: #292929;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.list_and_slider_section .list_box .list_card .imgbox {
  width: 60px;
  flex-shrink: 0;
}
.list_and_slider_section .slider_box {
  padding: 0 60px;
}
.list_and_slider_section .slider_box .title {
  color: #3e3e3e;
  font-family: "Inter", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}
.list_and_slider_section .slider_box .need_card {
  padding: 0 12px;
}
.list_and_slider_section .slider_box .need_card .head {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
  margin-bottom: 24px;
}
.list_and_slider_section .slider_box .need_card .head .imgbox {
  border-radius: 5px;
  aspect-ratio: 1/1;
}
.list_and_slider_section .slider_box .need_card .body .title {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.list_and_slider_section .need_slider .slick-arrow {
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
}
.list_and_slider_section .need_slider .slick-arrow.slick-prev {
  left: -50px;
}
.list_and_slider_section .need_slider .slick-arrow.slick-next {
  right: -50px;
}
.list_and_slider_section .need_slider .slick-arrow::before {
  color: #000;
}
@media only screen and (max-width: 768px) {
  .list_and_slider_section .list_box .list_card {
    flex-direction: column-reverse;
    row-gap: 32px;
  }
  .list_and_slider_section .list_box .list_card .text_box {
    text-align: center;
  }
  .list_and_slider_section .slider_box {
    padding: 0 0px;
  }
  .list_and_slider_section .need_slider .slick-arrow.slick-prev {
    left: 0px;
  }
  .list_and_slider_section .need_slider .slick-arrow.slick-next {
    right: 0px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/product-grid/productgrid.css */
.product_grid_section {
  padding: 60px 0;
}
.product_grid_section .heading {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.product_grid_section .discover_card {
  border-radius: 16px;
  background: #f0f1f7;
  padding: 20px 10px;
  height: 100%;
}
.product_grid_section .discover_card .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;
  margin-bottom: 20px;
}
.product_grid_section .discover_card .head .left_box .title {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.product_grid_section .discover_card .head .left_box .subtitle {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product_grid_section .discover_card .head .right_box .view_more {
  color: #333;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.product_grid_section .discover_card .head .right_box .view_more,
.product_grid_section .discover_card .head .right_box .view_more * {
  cursor: pointer;
}
.product_grid_section .product_grid_card .head {
  margin-bottom: 15px;
}
.product_grid_section .product_grid_card .head .imgbox_full {
  border-radius: 35px;
}
.product_grid_section .product_grid_card .body {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 20px;
}
.product_grid_section .product_grid_card .body .title_box .badge {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0;
}
.product_grid_section .product_grid_card .body .title_box .title {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.product_grid_section .product_grid_card .body .price_box .price {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.product_grid_section .product_grid_card.lg_image .imgbox_full {
  aspect-ratio: 1/1;
}
.product_grid_section .product_grid_card.md_image .imgbox_full {
  aspect-ratio: 2/3;
}
.product_grid_section .product_grid_card.sm_image .imgbox_full {
  aspect-ratio: 2/1;
}
@media only screen and (max-width: 768px) {
  .product_grid_section .product_grid_card.lg_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .product_grid_card.md_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .product_grid_card.sm_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .heading {
    font-size: 26px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/testimonial/testimonial.css */
.testimonial_section {
  padding: 60px 0;
  background-color: #FFF;
}
.testimonial_section .heading {
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  max-width: 1100px;
  margin: 0 auto 20px;
}
.testimonial_section .pera {
  color: #666;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  max-width: 965px;
  margin: 0 auto 20px;
}
.testimonial_section .learn_more {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.08px;
  text-decoration: underline;
  text-underline-offset: 4px;
  display: block;
  margin-bottom: 20px;
}
.testimonial_section .learn_more,
.testimonial_section .learn_more * {
  cursor: pointer;
}
.testimonial_section .testimonial_slider .slick-arrow {
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background: #fff;
  z-index: 1;
}
.testimonial_section .testimonial_slider .swiper-button-next.swiper-button-disabled,
.testimonial_section .testimonial_slider .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
.testimonial_section .testimonial_slider .swiper-button-next svg,
.testimonial_section .testimonial_slider .swiper-button-prev svg {
  width: 100%;
  height: 100%;
  fill: #000;
}
.testimonial_section .testimonial_slider .slick-arrow::before {
  color: #000;
}
.testimonial_section .testimonial_card {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 112px 120px;
  background: #F0F1F7;
  border-radius: 12px;
}
.testimonial_section .testimonial_card .profile_box {
  display: flex;
  align-items: center;
  gap: 20px;
}
.testimonial_section .testimonial_card .profile_box .imgbox {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.testimonial_section .testimonial_card .profile_box .text_box .title {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;
}
.testimonial_section .testimonial_card .profile_box .text_box .subtitle {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.testimonial_section .testimonial_card .testimonial_text .pera {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  max-width: 600px;
}
.testimonial_section .slick-prev {
  left: 20px;
}
.testimonial_section .slick-next {
  right: 20px;
}
.testimonial_section .testimonial_text {
  min-width: 50%;
}
@media only screen and (max-width: 992px) {
  .testimonial_section .testimonial_card {
    padding: 12px 20px;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .testimonial_section .testimonial_card .profile_box {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .testimonial_section .slick-prev {
    left: 0px;
  }
  .testimonial_section .slick-next {
    right: 0px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/resource-section/resourcesection.css */
.resources_section {
  padding: 34px 0 68px;
  background-color: #f0f1f7;
}
.resources_section .subheading {
  color: #808183;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
.resources_section .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44.8px;
  margin-bottom: 48px;
}
.resources_section .resource_card {
  border-radius: 16px;
  background: #fff;
  padding: 32px;
  height: 100%;
}
.resources_section .resource_card .head {
  margin-bottom: 24px;
  aspect-ratio: 2 / 1.2;
}
.resources_section .resource_card .head .imgbox {
  aspect-ratio: 2 / 1.2;
}
.resources_section .resource_card .body .title {
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}
.resources_section .resource_card .body .pera {
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.resources_section .resource_card .body .btn {
  display: flex;
  align-items: center;
  color: #00838f;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0 !important;
}
.resources_section .resource_card .body .btn .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.resources_section .resource_card .body .btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.resources_section .resource_card .body .btn,
.resources_section .resource_card .body .btn * {
  cursor: pointer;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/about-banner/aboutbanner.css */
.about_banner_section {
  background-image: url(/build/_assets/about-banner-IMT7KVKS.png);
  margin-top: 80px;
  background-size: fit;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f0f1f7;
}
.about_banner_section .text_content_wrapper .text_content {
  min-height: 70vh;
  padding: 100px 0;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about_banner_section .text_content_wrapper .text_content .badge {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  border-radius: 20px;
  background: rgba(75, 78, 103, 0.4);
  padding: 2px 8px;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 12px;
}
.about_banner_section .text_content_wrapper .text_content .heading {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  margin-bottom: 24px;
}
.about_banner_section .text_content_wrapper .text_content .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.1px;
  margin-bottom: 32px;
}
.about_banner_section .text_content_wrapper .text_content .button_box {
  display: flex;
  align-items: center;
  gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn {
  border-radius: 6px;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 12px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 250ms;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn .icon_box svg * {
  stroke: currentColor;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn,
.about_banner_section .text_content_wrapper .text_content .button_box .btn * {
  cursor: pointer;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn:hover {
  border: 1px solid #e5e5e7;
  background: #fff;
  color: #323539;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/count-list/countlist.css */
.count_list_section {
  padding: 64px 0;
}
.count_list_section .count_card .heading {
  color: #4b4e67;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
}
.count_list_section .count_card .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/free-listing/freelisting.css */
.free_listing_section {
  padding: 36px 0;
  background-color: #FFF;
}
.free_listing_section .listing_box {
  padding: 48px;
  background-color: #F0F1F7;
  border-radius: 20px;
}
.free_listing_section .listing_box .text_box {
  padding: 48px;
  background-color: #FFF;
  border-radius: 20px;
  border: 4px solid rgba(75, 78, 103, 0.50);
}
.free_listing_section .listing_box .text_box .pera {
  color: #4B4E67;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 40px;
}
.free_listing_section .listing_box .text_box .btn {
  color: #FFF;
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 32px;
  border-radius: 8px;
  background-color: #4b4e67;
  position: relative;
  overflow: hidden;
}
.free_listing_section .listing_box .text_box .btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.free_listing_section .listing_box .text_box .btn:hover::before {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .free_listing_section .listing_box {
    padding: 18px;
  }
  .free_listing_section .listing_box .text_box {
    padding: 20px;
  }
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/list-text/listtext.css */
.list_and_text_section {
  padding: 60px 0;
  background-color: #f0f1f7;
}
.list_and_text_section .text_content {
  max-width: 550px;
}
.list_and_text_section .text_content .heading {
  color: #333;
  font-family: "Google Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 26px;
}
.list_and_text_section .text_content .pera {
  color: #333;
  font-family: "Google Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 26px;
}
.list_and_text_section .text_content .btn {
  border-radius: 30px;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 12px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 250ms;
  width: -moz-fit-content;
  width: fit-content;
}
.list_and_text_section .text_content .btn .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.list_and_text_section .text_content .btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.list_and_text_section .text_content .btn .icon_box svg * {
  stroke: currentColor;
}
.list_and_text_section .text_content .btn,
.list_and_text_section .text_content .btn * {
  cursor: pointer;
}
.list_and_text_section .text_content .btn:hover {
  border: 1px solid #e5e5e7;
  background: #fff;
  color: #323539;
}
.list_and_text_section .list_content {
  display: flex;
  justify-content: center;
}
.list_and_text_section .list_content .list .item {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.list_and_text_section .list_content .list .item .imgbox {
  width: 42px;
  flex-shrink: 0;
}
.list_and_text_section .list_content .list .item .text_box .title {
  color: #4b4e67;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
}
.list_and_text_section .list_content .list .item .text_box .pera {
  color: #333;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/blog-banner/blogbanner.css */
.blog_banner_section {
  padding: 120px 0;
  background-image: url(/build/_assets/blog-banner-7J6WYUIN.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}
.blog_banner_section .back_btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #2a2c32;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 23px;
}
.blog_banner_section .back_btn .icon_box {
  width: 20px;
  height: 20px;
}
.blog_banner_section .back_btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
  margin-top: -6px;
}
.blog_banner_section .back_btn,
.blog_banner_section .back_btn * {
  cursor: pointer;
}
.blog_banner_section .title {
  color: #242f65;
  text-align: center;
  font-family: "Fraunces", serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/blog-grid/bloggrid.css */
.blog_grid_section {
  padding: 60px 0;
}
.blog_grid_section .blog_card {
  margin-bottom: 30px;
}
.blog_grid_section .blog_card .head {
  margin-bottom: 30px;
}
.blog_grid_section .blog_card .head .imgbox_full {
  aspect-ratio: 5/3;
  border-radius: 20px;
}
.blog_grid_section .blog_card .body .text_content .date {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%;
  margin-bottom: 14px;
}
.blog_grid_section .blog_card .body .text_content .title {
  color: #242f65;
  font-family: "Fraunces", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 20px;
}
.blog_grid_section .blog_card .body .text_content .pera {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 50px;
}
.blog_grid_section .blog_card .body .text_content .view_more {
  color: #242f65;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
}
.blog_grid_section .blog_card .body .text_content .view_more .icon_box {
  height: 16px;
  width: 16px;
}
.blog_grid_section .blog_card .body .text_content .view_more .icon_box svg {
  height: 100%;
  width: 100%;
  margin-top: -6px;
}
.blog_grid_section .blog_card .body .text_content .view_more,
.blog_grid_section .blog_card .body .text_content .view_more * {
  cursor: pointer;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/featured-blog/feturedblog.css */
.featured_blog_section .container {
  padding: 80px 0 120px;
  border-bottom: 1px solid #aeaeae;
}
.featured_blog_section .imgbox_full {
  aspect-ratio: 5/3;
  border-radius: 20px;
}
.featured_blog_section .text_content .date {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%;
  margin-bottom: 14px;
}
.featured_blog_section .text_content .title {
  color: #242f65;
  font-family: "Fraunces", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 20px;
}
.featured_blog_section .text_content .pera {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 50px;
}
.featured_blog_section .text_content .view_more {
  color: #242f65;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
}
.featured_blog_section .text_content .view_more .icon_box {
  height: 16px;
  width: 16px;
}
.featured_blog_section .text_content .view_more .icon_box svg {
  height: 100%;
  width: 100%;
  margin-top: -6px;
}
.featured_blog_section .text_content .view_more,
.featured_blog_section .text_content .view_more * {
  cursor: pointer;
}

/* css-side-effects-plugin-ns:app/components/landingPage/sections/faq/faq.css */
.faq_section {
  margin-top: 80px;
  padding: 64px 0;
}
.faq_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
}
.faq_section .pera {
  color: #353535;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 64px;
}
.faq_section .accordion_box .accordion_item {
  border-radius: 6px;
  border: 1px solid #e5e5e7;
  margin-bottom: 16px;
  overflow: hidden;
}
.faq_section .accordion_box .accordion_item .accordion_title {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title {
  background-color: #4b4e67;
}
.faq_section .accordion_box .accordion_item .accordion_title,
.faq_section .accordion_box .accordion_item .accordion_title * {
  cursor: pointer;
}
.faq_section .accordion_box .accordion_item .accordion_title .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 0;
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .title {
  color: #fff;
}
.faq_section .accordion_box .accordion_item .accordion_title .icon_box {
  width: 20px;
  height: 20px;
  color: #323539;
  transform: rotate(180deg);
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .icon_box {
  color: #fff;
}
.faq_section .accordion_box .accordion_item .accordion_title .icon_box svg {
  stroke: currentColor;
  margin-top: -6px;
}
.faq_section .accordion_box .accordion_item .accordion_title .icon_box svg path {
  stroke: currentColor;
}
.faq_section .accordion_box .accordion_item .accordion_content {
  height: 0px;
  overflow: hidden;
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item .accordion_content .content_wrapper {
  padding: 12px 20px 24px 20px;
}
.faq_section .accordion_box .accordion_item .accordion_content .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-align: left;
  margin-bottom: 0;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .icon_box {
  transform: rotate(0deg);
}
/*# sourceMappingURL=/build/css-bundle-6XNHAVUB.css.map */
