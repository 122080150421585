@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./../../public/font/ProductSans-Regular.woff") format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 100;
  src: url("./../../public/font/ProductSans-Thin.woff") format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 300;
  src: url("./../../public/font/ProductSans-Light.woff") format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 500;
  src: url("./../../public/font/ProductSans-Medium.woff") format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 600;
  src: url("./../../public/font/ProductSans-Black.woff") format("woff");
}
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./../../public/font/ProductSans-Bold.woff") format("woff");
}

.form-control:focus {
  border-color: var(
    --kt-primary,
    #3699ff
  ) !important; /* Adjust this to match Metronic's primary color */
  box-shadow: 0 0 0 0.2rem rgba(54, 153, 255, 0.25); /* Optional: Adds a shadow effect */
}
.imgbox_full,
.imgbox {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.imgbox_full img,
.imgbox img {
  width: 100%;
  height: auto;
}

.imgbox_full {
  height: 100%;
}
.imgbox_full img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #fff;
  color: #000;
  position: static;
  scroll-behavior: smooth;
}

.main_content {
  min-height: 60vh;
  display: contents;
}

/* .main_content {
  height: auto;
  min-height: 100vh;
} */

select,
input,
button:focus {
  outline: none;
}

ul:not(.jodit-react-container ul):not(.rich_text ul),
ol:not(.jodit-react-container ol):not(.rich_text ol) {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

.menu {
  display: block;
}

.cm_title_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 30px;
  padding: 10px 0;
}
.cm_title_box .title_box {
  padding-left: 30px;
  position: relative;
}
.cm_title_box .title_box h2 {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
.cm_title_box .title_box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 20px;
  border-radius: 8px;
  background-color: #4b4e67;
}
.cm_title_box .shop_all {
  display: block;
  border-radius: 1000px;
  border: 1px solid #4b4e67;
  background-color: #fff;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 24px;
  transition: all 250ms;
}
.cm_title_box .shop_all:hover {
  background-color: #4b4e67;
  color: #ffffff;
}
.cm_title_box .shop_all,
.cm_title_box .shop_all * {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .cm_title_box .shop_all {
    padding: 4px 12px;
  }
}
.product_card {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
}
.product_card .product_image,
.product_card .product_details,
.product_card .product_tags {
  width: calc(33.3333333333% - 10.6666666667px);
}
.product_card .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.product_card .product_image .imgbox {
  max-width: 300px;
  margin: 0 auto;
}
.product_card .product_details .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 11px 0;
}
.product_card .product_details .product_price .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.product_card .product_details .product_price .main_price .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.product_card .product_details .product_price .main_price .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.product_card .product_details .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .unit_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
}
.product_card .product_details .unit_box .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .unit_box .unit {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .list_box {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}
.product_card .product_details .list_box .list_content {
  width: calc(50% - 4px);
}
.product_card .product_details .list_box .list_content.list_contact {
  width: 100%;
}
.product_card .product_details .list_box .list_content.list_contact.half_width {
  width: calc(50% - 4px);
}
.product_card .product_details .list_box .list_content .title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}
.product_card .product_details .list_box .list_content .list li {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  overflow-x: hidden;
}
.product_card .product_details .list_box .list_content .list li .icon_box {
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.product_card .product_details .list_box .list_content .list li .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_card .product_details .list_box .list_content .list li .text_box,
.product_card .product_details .list_box .list_content .list li .text_box * {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.product_card .product_details .list_box .list_content .list li .text_box a,
.product_card .product_details .list_box .list_content .list li .text_box a * {
  cursor: pointer;
}
.product_card .product_tags .tag_line {
  color: #959595;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.product_card .product_tags .tags_content {
  margin: 8px 0 12px;
}
.product_card .product_tags .tags_content .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.product_card .product_tags .tags_content .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #4b4e67;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product_card .product_tags .button_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 12px;
}
.product_card .product_tags .button_box .follow_btn,
.product_card .product_tags .button_box .chat_btn {
  width: calc(50% - 8px);
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background: #fff;
  padding: 1.5px 12px;
  color: rgba(0, 0, 0, 0.3137254902);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
}
.product_card .product_tags .button_box .follow_btn::before,
.product_card .product_tags .button_box .chat_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.product_card .product_tags .button_box .follow_btn:hover::before,
.product_card .product_tags .button_box .chat_btn:hover::before {
  width: 100%;
}
.product_card .product_tags .button_box .follow_btn,
.product_card .product_tags .button_box .follow_btn *,
.product_card .product_tags .button_box .chat_btn,
.product_card .product_tags .button_box .chat_btn * {
  cursor: pointer;
}
.product_card .product_tags .button_box .follow_btn .icon_box,
.product_card .product_tags .button_box .chat_btn .icon_box {
  width: 20px;
  height: 20px;
  padding: 2.464px 1.267px 2.466px 1.749px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product_card .product_tags .button_box .follow_btn .icon_box svg,
.product_card .product_tags .button_box .chat_btn .icon_box svg {
  height: 100%;
  width: 100%;
}
.product_card .product_tags .button_box .connect_btn {
  width: 100%;
  border: none;
  border-radius: 8px;
  background: #4b4e67;
  padding: 6px 24px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.product_card .product_tags .button_box .connect_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.product_card .product_tags .button_box .connect_btn:hover::before {
  width: 100%;
}
.product_card .product_tags .button_box .connect_btn,
.product_card .product_tags .button_box .connect_btn * {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .product_card .product_details,
  .product_card .product_tags {
    width: calc(50% - 8px);
  }
  .product_card .product_image {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .product_card {
    padding: 12px;
  }
  .product_card .product_details,
  .product_card .product_tags {
    width: 100%;
  }
  .product_card .product_image {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .product_card .product_details .list_box .list_content {
    width: 100%;
  }
}
.sm_product_card .head {
  position: relative;
  border-radius: 4px;
  background-color: #f5f5f5;
  padding: 35px 40px;
  margin-bottom: 16px;
}
.sm_product_card .head .imgbox_full {
  aspect-ratio: 1/1;
}
.sm_product_card .head .offer_badge {
  border-radius: 4px;
  background-color: #4b4e67;
  padding: 4px 12px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: absolute;
  left: 12px;
  top: 12px;
}
.sm_product_card .head .button_box {
  position: absolute;
  top: 12px;
  right: 12px;
}
.sm_product_card .head .button_box button {
  padding: 8px;
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  margin-bottom: 8px;
}
.sm_product_card .head .button_box button,
.sm_product_card .head .button_box button * {
  cursor: pointer;
}
.sm_product_card .head .button_box button svg {
  width: 100%;
  height: 100%;
}
.sm_product_card .body {
  padding: 0 0 20px 0;
}
.sm_product_card .body .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sm_product_card .body .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 8px 0 12px;
}
.sm_product_card .body .product_price .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.sm_product_card .body .product_price .main_price .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.sm_product_card .body .product_price .main_price .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.sm_product_card .body .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.sm_product_card .body .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.sm_product_card .body .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #808080;
  color: #808080;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1882352941);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all linear 0.1s;
}
.modal .modal_inner {
  width: 760px;
  height: 500px;
  overflow-y: scroll;
  background-color: #ffffff;
  padding: 10px 10px 10px 20px;
  border-radius: 6px;
  margin-top: 20px;
  transition: all linear 0.3s;
  transition-delay: 0.1s;
}
.modal .modal_inner::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}
.modal .modal_inner::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2509803922);
}
.modal .modal_inner .top_bar {
  display: flex;
  justify-content: space-between;
}
.modal .modal_inner .top_bar .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
}
.modal .modal_inner .top_bar .close {
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
}
.modal .modal_inner .top_bar .close svg {
  width: 100%;
  height: 100%;
}
.modal .modal_inner .top_bar .close,
.modal .modal_inner .top_bar .close * {
  cursor: pointer;
}
.modal .modal_inner .top_bar .close:hover {
  background-color: rgba(0, 0, 0, 0.1254901961);
}
.modal .modal_content video {
  width: 100%;
  height: auto;
}
.modal.active {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.modal.active .modal_inner {
  margin-top: 0px;
}

.search_result_wrapper {
  position: absolute;
  left: 0;
  top: 100%;
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  width: 100%;
  height: auto;
  transition: all ease 350ms;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.search_result_wrapper .search_list li {
  margin-bottom: 10px;
}
.search_result_wrapper .search_list li a {
  font-size: 18px;
  color: #000;
}
.search_result_wrapper .search_list li a,
.search_result_wrapper .search_list li a * {
  cursor: pointer;
}
.search_result_wrapper.active {
  opacity: 1;
  visibility: visible;
}

.progress_wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #111111;
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress_wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.progress_wrap svg path {
  fill: none;
}
.progress_wrap svg.progress-circle path {
  stroke: #ffffff;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress_wrap .chev {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  fill: #ffffff;
  stroke: #ffffff;
  transition: all ease-in-out 350ms;
}

.progress_wrap .chev path {
  fill: #ffffff;
  stroke: #ffffff;
}

.progress_wrap.active-progress .chev {
  top: 50%;
}

.custom-separator {
  border-bottom: 1px solid #e0e3f1;
  margin: 10px;
}

.custom-menu-link {
  color: #111111;
}

.chart-container {
  position: relative;
  margin: auto;
  height: 300px; /* Adjust height as needed */
  width: 100%; /* Ensure the chart takes full width */
}

.jodit-status-bar-link {
  display: none;
}

.hover-item {
  cursor: pointer;
  padding: 2px; /* Adding padding around the link */
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  border-radius: 4px; /* Optional: round the corners for a better look */
}

.hover-item:hover {
  background-color: #f0f0f0; /* Change this to your desired hover background color */
  color: #333; /* Change this to your desired hover text color */
}

/* .rich_text.text_box * {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
}

.rich_text.text_box h1,
.rich_text.text_box h2,
.rich_text.text_box h3,
.rich_text.text_box h4,
.rich_text.text_box h5,
.rich_text.text_box h6 {
  color: #000;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.18px;
  margin-bottom: 20px;
}

.rich_text.text_box h1{
  font-size: 36px;
}

.rich_text.text_box h2{
  font-size: 32px;
} */
