.filter_accordion .title_box {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 24px;
  border-bottom: 1px solid #ececec;
}

.filter_accordion .title_box .icon_box {
  width: 15px;
  height: 30px;
  transition: all 250ms;
  transform: rotate(180deg);
}

.filter_accordion .title_box .icon_box svg {
  width: 100%;
  height: 100%;
}

.filter_accordion .title_box h2 {
  color: #7f7f7f;
  font-family: "Google Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  margin: 0;
}

.filter_accordion .title_box,
.filter_accordion .title_box * {
  cursor: pointer;
}

.filter_accordion .content_box {
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: all 250ms;
}

.filter_accordion.active .title_box .icon_box {
  transform: rotate(0deg);
}

.filter_accordion.active .title_box .icon_box svg {
  width: 100%;
  height: 100%;
}

.filter_accordion.active .content_box {
  padding: 16px 0px;
  height: auto;
  border-bottom: 1px solid #ececec;
}
