.terms_section {
  padding: 50px 0;
}
.terms_section .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.22px;
}
.terms_section .text_box .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
}
.terms_section .text_box .list li {
  padding-left: 25px;
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 18px;
  position: relative;
}
.terms_section .text_box .list li::before {
  content: "";
  background-image: url("./../../../../../public/image/Checkbox.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px;
  width: 18px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
