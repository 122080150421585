.list_and_slider_section {
  padding: 60px 0;
}
.list_and_slider_section .c_pera {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 60px;
}
.list_and_slider_section .list_box{
    margin-bottom: 32px;
}
.list_and_slider_section .list_box .list_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  gap: 16px;
  margin-bottom: 32px;
}
.list_and_slider_section .list_box .list_card .text_box .title {
  color: #0b0b0b;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}
.list_and_slider_section .list_box .list_card .text_box .pera {
  color: #292929;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}
.list_and_slider_section .list_box .list_card .imgbox {
  width: 60px;
  flex-shrink: 0;
}
.list_and_slider_section .slider_box {
  padding: 0 60px;
}
.list_and_slider_section .slider_box .title {
  color: #3e3e3e;
  font-family: "Inter", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
}
.list_and_slider_section .slider_box .need_card {
  padding: 0 12px;
}
.list_and_slider_section .slider_box .need_card .head {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  padding: 8px;
  margin-bottom: 24px;
}
.list_and_slider_section .slider_box .need_card .head .imgbox {
  border-radius: 5px;
  aspect-ratio: 1/1;
}
.list_and_slider_section .slider_box .need_card .body .title {
  color: #3e3e3e;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
}
.list_and_slider_section .need_slider .slick-arrow {
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 1;
}
.list_and_slider_section .need_slider .slick-arrow.slick-prev {
  left: -50px;
}
.list_and_slider_section .need_slider .slick-arrow.slick-next {
  right: -50px;
}

.list_and_slider_section .need_slider .slick-arrow::before {
  color: #000;
}

@media only screen and (max-width: 768px) {
  .list_and_slider_section .list_box .list_card {
    flex-direction: column-reverse;
    row-gap: 32px;
  }
  .list_and_slider_section .list_box .list_card .text_box {
    text-align: center;
  }
  .list_and_slider_section .slider_box {
    padding: 0 0px;
  }
  .list_and_slider_section .need_slider .slick-arrow.slick-prev {
    left: 0px;
  }
  .list_and_slider_section .need_slider .slick-arrow.slick-next {
    right: 0px;
  }
}
