.testimonial_section {
  padding: 60px 0;
  background-color: #FFF;
}
.testimonial_section .heading {
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  max-width: 1100px;
  margin: 0 auto 20px;
}
.testimonial_section .pera {
  color: #666;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  max-width: 965px;
  margin: 0 auto 20px;
}
.testimonial_section .learn_more {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.08px;
  text-decoration: underline;
  text-underline-offset: 4px;
  display: block;
  margin-bottom: 20px;
}
.testimonial_section .learn_more,
.testimonial_section .learn_more * {
  cursor: pointer;
}
.testimonial_section .testimonial_slider .slick-arrow {
  padding: 10px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e5e5;
  background: #fff;
  z-index: 1;
}
.testimonial_section
  .testimonial_slider
  .swiper-button-next.swiper-button-disabled,
.testimonial_section
  .testimonial_slider
  .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
.testimonial_section .testimonial_slider .swiper-button-next svg,
.testimonial_section .testimonial_slider .swiper-button-prev svg {
  width: 100%;
  height: 100%;
  fill: #000;
}
.testimonial_section .testimonial_slider .slick-arrow::before {
  color: #000;
}
.testimonial_section .testimonial_card {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 112px 120px;
  background: #F0F1F7;
  border-radius: 12px;
}
.testimonial_section .testimonial_card .profile_box {
  display: flex;
  align-items: center;
  gap: 20px;
}
.testimonial_section .testimonial_card .profile_box .imgbox {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.testimonial_section .testimonial_card .profile_box .text_box .title {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 8px;
}
.testimonial_section .testimonial_card .profile_box .text_box .subtitle {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 0;
}
.testimonial_section .testimonial_card .testimonial_text .pera {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  max-width: 600px;
}

.testimonial_section .slick-prev {
  left: 20px;
}
.testimonial_section .slick-next {
  right: 20px;
}

.testimonial_section .testimonial_text{
  min-width: 50%;
}

@media only screen and (max-width: 992px) {
  .testimonial_section .testimonial_card {
    padding: 12px 20px;
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .testimonial_section .testimonial_card .profile_box {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }

  .testimonial_section .slick-prev {
    left: 0px;
  }
  .testimonial_section .slick-next {
    right: 0px;
  }
}
