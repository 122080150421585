@media (max-width: 650px) {
  .table.subscription-details-table {
    width: 100%;
    display: table !important;
  }
  .table.subscription-details-table > :not(caption) > * > * {
    padding-left: 0 !important;
  }
  .table.subscription-details-table tbody {
    display: table-row-group !important;
  }

  .table.subscription-details-table tr {
    display: table-row !important;
  }

  .table.subscription-details-table th {
    display: none;
  }
  .table.subscription-details-table td {
    display: grid !important;
    grid-template-columns: 17ch auto !important;
    width: 100% !important;
  }

  .table.subscription-details-table td::before {
    content: attr(data-cell) " ";
    font-weight: 700;
    text-transform: capitalize;
    color: #3d3d3d;
  }
}
