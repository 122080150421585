.list_and_text_section {
  padding: 60px 0;
  background-color: #f0f1f7;
}
.list_and_text_section .text_content {
  max-width: 550px;
}
.list_and_text_section .text_content .heading {
  color: #333;
  font-family: "Google Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 26px;
}
.list_and_text_section .text_content .pera {
  color: #333;
  font-family: "Google Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 26px;
}
.list_and_text_section .text_content .btn {
  border-radius: 30px;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 12px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 250ms;
  width: -moz-fit-content;
  width: fit-content;
}
.list_and_text_section .text_content .btn .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.list_and_text_section .text_content .btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.list_and_text_section .text_content .btn .icon_box svg * {
  stroke: currentColor;
}
.list_and_text_section .text_content .btn,
.list_and_text_section .text_content .btn * {
  cursor: pointer;
}
.list_and_text_section .text_content .btn:hover {
  border: 1px solid #e5e5e7;
  background: #fff;
  color: #323539;
}
.list_and_text_section .list_content {
  display: flex;
  justify-content: center;
}
.list_and_text_section .list_content .list .item {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.list_and_text_section .list_content .list .item .imgbox {
  width: 42px;
  flex-shrink: 0;
}
.list_and_text_section .list_content .list .item .text_box .title {
  color: #4b4e67;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
}
.list_and_text_section .list_content .list .item .text_box .pera {
  color: #333;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
