.free_listing_section {
  padding: 36px 0;
  background-color: #FFF;
}
.free_listing_section .listing_box {
  padding: 48px;
  background-color: #F0F1F7;;
  border-radius: 20px;
}
.free_listing_section .listing_box .text_box {
  padding: 48px;
  background-color: #FFF;
  border-radius: 20px;
  border: 4px solid rgba(75, 78, 103, 0.50);
}
.free_listing_section .listing_box .text_box .pera {
  color: #4B4E67;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 40px;
}
.free_listing_section .listing_box .text_box .btn {
  color: #FFF;
  text-align: center;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  padding: 12px 32px;
  border-radius: 8px;
  background-color: #4b4e67;
  position: relative;
  overflow: hidden;
}

.free_listing_section .listing_box .text_box .btn::before{
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.free_listing_section .listing_box .text_box .btn:hover::before{
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .free_listing_section .listing_box {
    padding: 18px;
  }
  .free_listing_section .listing_box .text_box {
    padding: 20px;
  }
}
