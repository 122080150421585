.terms_banner_section {
  margin-top: 80px;
  padding: 80px 0;
  background-color: #e5e5e7;
}
.terms_banner_section .badge {
  border-radius: 128px;
  background: rgba(75, 78, 103, 0.4);
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 12px;
}
.terms_banner_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  margin-bottom: 24px;
}
.terms_banner_section .pera {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 640px;
  margin: 0 auto 36px;
}
.terms_banner_section .search_box {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #e5e5e7;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 8px 8px 8px 16px;
  max-width: 640px;
  margin: 0 auto;
}
.terms_banner_section .search_box .search_icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.terms_banner_section .search_box .search_icon svg {
  width: 100%;
  height: 100%;
  margin-top: -6px;
}
.terms_banner_section .search_box .input_field {
  width: calc(100% - 30px - 30%);
  border: none;
  background-color: transparent;
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.terms_banner_section .search_box .input_field::-webkit-input-placeholder {
  color: #858c95;
}
.terms_banner_section .search_box .submit_btn {
  width: 30%;
  border-radius: 6px;
  border: none;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 10px 16px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
