.main_product_section {
  margin-top: 80px;
  padding: 32px 0;
}
.main_product_section .product_content {
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;
}
.main_product_section .product_content .heading {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.main_product_section .product_content .product_details,
.main_product_section .product_content .product_tags {
  width: calc(40% - 8px);
}
.main_product_section .product_content .product_details .product_price {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
  margin: 11px 0;
}
.main_product_section
  .product_content
  .product_details
  .product_price
  .main_price {
  display: flex;
  align-items: center;
  gap: 8px;
}
.main_product_section
  .product_content
  .product_details
  .product_price
  .main_price
  .crnt_price {
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.main_product_section
  .product_content
  .product_details
  .product_price
  .main_price
  .offer_price {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: line-through;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .product_price .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .unit_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  row-gap: 10px;
}
.main_product_section .product_content .product_details .unit_box .text {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .unit_box .unit {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}
.main_product_section .product_content .product_details .tags_content {
  margin: 8px 0 12px;
}
.main_product_section .product_content .product_details .tags_content .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  row-gap: 12px;
}
.main_product_section .product_content .product_details .tags_content .tags li {
  padding: 2px 10px;
  border-radius: 4px;
  border: 1px solid #4b4e67;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.main_product_section .product_content .product_tags .tag_line {
  color: #959595;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.main_product_section .product_content .product_tags .contact_list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}
.main_product_section .product_content .product_tags .contact_list li a {
  border-radius: 50%;
  border: 1px solid #e8e8e8;
  background: #fafafa;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  position: relative;
  overflow: hidden;
}
.main_product_section .product_content .product_tags .contact_list li a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section .product_content .product_tags .contact_list li a:hover::before {
  width: 100%;
}
.main_product_section .product_content .product_tags .contact_list li a,
.main_product_section .product_content .product_tags .contact_list li a * {
  cursor: pointer;
}
.main_product_section .product_content .product_tags .contact_list li a svg {
  width: 100%;
  height: 100%;
}
.main_product_section .product_content .product_tags .button_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 12px;
}
.main_product_section .product_content .product_tags .button_box .follow_btn,
.main_product_section .product_content .product_tags .button_box .chat_btn {
  width: 40px;
  border-radius: 8px;
  border: 1px solid #4b4e67;
  background: #fff;
  padding: 1.5px;
  color: rgba(0, 0, 0, 0.3137254902);
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  overflow: hidden;
}

.main_product_section .product_content .product_tags .button_box .follow_btn::before,
.main_product_section .product_content .product_tags .button_box .chat_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section .product_content .product_tags .button_box .follow_btn:hover::before,
.main_product_section .product_content .product_tags .button_box .chat_btn:hover::before{
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .follow_btn,
.main_product_section .product_content .product_tags .button_box .follow_btn *,
.main_product_section .product_content .product_tags .button_box .chat_btn,
.main_product_section .product_content .product_tags .button_box .chat_btn * {
  cursor: pointer;
}
.main_product_section
  .product_content
  .product_tags
  .button_box
  .follow_btn
  .icon_box,
.main_product_section
  .product_content
  .product_tags
  .button_box
  .chat_btn
  .icon_box {
  width: 20px;
  height: 20px;
  padding: 2.464px 1.267px 2.466px 1.749px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main_product_section
  .product_content
  .product_tags
  .button_box
  .follow_btn
  .icon_box
  svg,
.main_product_section
  .product_content
  .product_tags
  .button_box
  .chat_btn
  .icon_box
  svg {
  height: 100%;
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .connect_btn {
  width: calc(100% - 120px);
  border: none;
  border-radius: 8px;
  background: #4b4e67;
  padding: 6px 24px;
  color: #fafafa;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.main_product_section
  .product_content
  .product_tags
  .button_box
  .connect_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.main_product_section
  .product_content
  .product_tags
  .button_box
  .connect_btn:hover::before {
  width: 100%;
}
.main_product_section .product_content .product_tags .button_box .connect_btn,
.main_product_section
  .product_content
  .product_tags
  .button_box
  .connect_btn
  * {
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .main_product_section .product_content .product_image {
    width: calc(40% - 8px);
  }
  .main_product_section .product_content .product_details {
    width: calc(60% - 8px);
  }
  .main_product_section .product_content .product_tags {
    width: 100%;
  }
}
@media only screen and (max-width: 500px) {
  .main_product_section .product_content .product_image,
  .main_product_section .product_content .product_details,
  .main_product_section .product_content .product_tags {
    width: 100%;
  }
}
@media only screen and (max-width: 400px) {
  .main_product_section .product_details .list_box .list_content {
    width: 100%;
  }
}
