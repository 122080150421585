.lp_header {
  padding: 10px 0px;
  position: absolute;
  top: 0;
  z-index: 5;
  width: 100%;
  background: #f5f7fa;
  --color: #18191f;
  --fill: #7e8299;
  --hover: #ebecf1;
}

.custom_container {
  min-height: 50vh;
  margin-top: 3.5rem;
}

.custom_input {
  height: 50px;
  width: 100%;
  border: 0;
  font-size: x-large;
  font-weight: bold;
  border-radius: 5px;
  background-color: transparent;
  color: white;
}

.lp_header .logo_light {
  display: block;
}
.lp_header .logo_dark {
  display: none;
}
.lp_header.dark {
  background: #4b4e67;
  --color: #ffffff;
  --fill: #ffffff;
  --hover: #515151;
}
.lp_header.dark .logo_light {
  display: none;
}
.lp_header.dark .logo_dark {
  display: block;
}
.lp_header.black {
  background: #111111;
  --color: #ffffff;
  --fill: #ffffff;
  --hover: #515151;
}
.lp_header.black .logo_light {
  display: none;
}
.lp_header.black .logo_dark {
  display: block;
}
.lp_header .content_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lp_header .content_wrapper .left_box .imgbox {
  width: 230px;
}
.lp_header .content_wrapper .center_box .menu {
  display: flex;
  align-items: center;
  gap: 50px;
}
.lp_header .content_wrapper .center_box .menu li a {
  color: var(--color);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  transition: all 350ms;
}
.lp_header .content_wrapper .center_box .menu li {
  position: relative;
}
.lp_header .content_wrapper .center_box .menu li::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
  width: 0%;
  height: 2px;
  border-radius: 10px;
  background-color: #8e93be;
  transition: all 350ms;
}
.lp_header .content_wrapper .center_box .menu li.active a,
.lp_header .content_wrapper .center_box .menu li:hover a {
  color: #8e93be;
}
.lp_header .content_wrapper .center_box .menu li.active::before,
.lp_header .content_wrapper .center_box .menu li:hover::before {
  width: 100%;
}
.lp_header .content_wrapper .center_box .menu li a,
.lp_header .content_wrapper .center_box .menu li a * {
  cursor: pointer;
}
.lp_header .content_wrapper .center_box .menu li .c_btn {
  padding: 15px 25px;
  border-radius: 3px;
  background: #fff;
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  position: relative;
  transition: all linear 350ms;
}
.lp_header .content_wrapper .center_box .menu li .c_btn::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.lp_header .content_wrapper .center_box .menu li .c_btn:hover::before {
  width: 100%;
}
.lp_header .content_wrapper .right_box {
  display: flex;
  align-items: center;
  gap: 25px;
}
.lp_header .content_wrapper .right_box .icon_list {
  display: flex;
  align-items: center;
  gap: 25px;
}
.lp_header .content_wrapper .right_box .icon_list li {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_header .content_wrapper .right_box .icon_list li svg {
  width: 20px;
  height: 20px;
}
.lp_header .content_wrapper .right_box .icon_list li svg * {
  fill: var(--fill);
}
.lp_header .content_wrapper .right_box .icon_list li,
.lp_header .content_wrapper .right_box .icon_list li * {
  cursor: pointer;
}
.lp_header .content_wrapper .right_box .icon_list li.active {
  background-color: var(--hover);
}
.lp_header .content_wrapper .right_box .menu_controller {
  width: 20px;
  height: 20px;
  display: none;
  color: var(--fill);
}
.lp_header .content_wrapper .right_box .menu_controller svg {
  width: 100%;
  height: 100%;
}
.lp_header .content_wrapper .right_box .menu_controller svg * {
  fill: currentColor;
}
.lp_header .content_wrapper .right_box .menu_controller,
.lp_header .content_wrapper .right_box .menu_controller * {
  cursor: pointer;
}
.lp_header .mobile_menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateX(-100%);
  width: 100%;
  height: calc(100vh - 100%);
  padding: 0 0 0;
  transition: all 350ms;
  background-color: #ffffff;
  z-index: 1;
}
.lp_header .mobile_menu .container {
  height: 100%;
}
.lp_header .mobile_menu .menu {
  height: calc(100% - 60px);
  display: block;
}
.lp_header .mobile_menu .menu li {
  padding: 10px;
}
.lp_header .mobile_menu .menu li a {
  color: #18191f;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.lp_header .mobile_menu .menu li a,
.lp_header .mobile_menu .menu li a * {
  cursor: pointer;
}
.lp_header .mobile_menu .icon_list {
  display: none;
  align-items: center;
  gap: 25px;
  height: 60px;
  padding: 20px;
}
.lp_header .mobile_menu .icon_list li {
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lp_header .mobile_menu .icon_list li svg {
  width: 20px;
  height: 20px;
}
.lp_header .mobile_menu .icon_list li,
.lp_header .mobile_menu .icon_list li * {
  cursor: pointer;
}
.lp_header .mobile_menu .icon_list li.active {
  background-color: #ebecf1;
}

@media only screen and (max-width: 1200px) {
  .lp_header .content_wrapper .center_box .menu {
    gap: 25px;
  }
  .lp_header .content_wrapper .right_box .icon_list {
    gap: 10px;
  }
}
@media only screen and (max-width: 992px) {
  .lp_header .content_wrapper .center_box {
    display: none;
  }
  .lp_header .content_wrapper .center_box .menu {
    gap: 25px;
  }
  .lp_header .content_wrapper .right_box .menu_controller {
    display: block;
  }
  .lp_header .mobile_menu {
    display: block;
  }
  .lp_header .mobile_menu.active {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 768px) {
  .lp_header .content_wrapper .left_box .imgbox {
    width: 200px;
  }
  .lp_header .content_wrapper .right_box .icon_list {
    display: none;
  }
  .lp_header .mobile_menu {
    display: block;
  }
  .lp_header .mobile_menu .icon_list {
    display: flex;
    gap: 5px;
  }
}
