.product_slider_section {
  padding: 45px 0;
}
.product_slider_section .cm_title_box {
  margin-bottom: 40px;
}

.product_slider_section .product_slider .swiper-slide{
    padding: 0 15px;
}
