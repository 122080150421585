.contact_banner_section {
  margin-top: 80px;
  padding: 90px 0;
  background-color: rgba(75, 78, 103, 0.062745098);
}
.contact_banner_section .tag {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  border-radius: 128px;
  background: rgba(75, 78, 103, 0.4);
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  padding: 4px 24px;
  margin: 0 auto 16px;
}
.contact_banner_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  max-width: 660px;
  margin: 0 auto 24px;
}
.contact_banner_section .paragraph {
  color: #858c95;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.1px;
  max-width: 640px;
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .contact_banner_section .tag {
    font-size: 10px;
  }
  .contact_banner_section .heading {
    font-size: 24px;
    line-height: 30px;
  }
  .contact_banner_section .paragraph {
    font-size: 14px;
    line-height: 18px;
  }
}
