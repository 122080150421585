.product_image {
  width: calc(20% - 16px);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.product_image .main_slider {
  margin-bottom: 10px;
}

.product_image .main_slider .swiper-button-next,
.product_image .main_slider .swiper-button-prev {
  width: 30px;
  height: 30px;
  padding: 7px;
  color: #959595;
  background-color: rgba(255, 255, 255, 0.3137254902);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product_image .main_slider .swiper-button-next svg,
.product_image .main_slider .swiper-button-prev svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}

.product_image .main_slider .swiper-button-next::after,
.product_image .main_slider .swiper-button-prev::after {
  content: none;
}

.product_image .thumb_slider .swiper-slide .imgbox_full {
  border-radius: 2px;
  padding: 5px;
}
.product_image .thumb_slider .swiper-slide .imgbox_full,
.product_image .thumb_slider .swiper-slide .imgbox_full *{
  cursor: pointer;
}
.product_image .imgbox_full {
  max-width: 300px;
  margin: 0 auto;
  aspect-ratio: 1/1;
}
