.career_info_section {
  margin-top: 80px;
}
.career_banner_section {
  padding: 100px 0;
  background-color: rgba(75, 78, 103, 0.062745098);
}
.headline {
  font-size: 3rem; /* Adjust to increase/decrease the font size */
  font-weight: bold;
}
.headline_1 {
  font-size: 2.5rem; /* Adjust to increase/decrease the font size */
  font-weight: bold;
}
.headline_2 {
  font-size: 2rem; /* Adjust to increase/decrease the font size */
  font-weight: bold;
}
