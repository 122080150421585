.image_with_list_section {
  padding: 60px 0;
}
.image_with_list_section .heading {
  color: #222;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.image_with_list_section .list .item {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  padding-bottom: 20px;
  padding-left: 10px;
  position: relative;
  width: 100%;
}
.image_with_list_section .list .item,
.image_with_list_section .list .item *{
  cursor: default;
}
.image_with_list_section .list .item .subtitle_box{
  display: flex;
  align-items: center;
  gap: 10px;
}
.image_with_list_section .list .item .icon_box {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 2px solid #d8d8d8;
  position: relative;
  overflow: visible;
  flex-shrink: 0;
}
.image_with_list_section .list .item .icon_box svg {
  width: 100%;
  height: 100%;
  fill: #4b4e67;
}
.image_with_list_section .list .item .text_box {
  max-width: calc(40%);
}
.image_with_list_section .list .item .subtitle {
  color: #4b4e67;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 5px;
}
.image_with_list_section .list .item .title {
  color: #4b4e67;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px 0;
}
.image_with_list_section .list .item .pera {
  color: #767676;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  opacity: 1;
  visibility: visible;
  height: auto;
}
.image_with_list_section .list .item .imgbox {
  width: 45%;
  position: absolute;
  right: 0;
  border: 2px solid #4b4e67;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
}
.image_with_list_section .list .item,
.image_with_list_section .list .item * {
  transition: all linear 350ms;
}
.image_with_list_section .list .item.active .icon_box {
  border: 2px solid #4b4e67;
  background-color: #4b4e67;
}
.image_with_list_section .list .item.active .icon_box svg {
  fill: #fff;
}
/* .image_with_list_section .list .item.active .pera {
  opacity: 1;
  visibility: visible;
  height: auto;
} */
.image_with_list_section .list .item.active .imgbox {
  top: 0;
  opacity: 1;
  visibility: visible;
}

.image_with_list_section .list .item:nth-last-child(1).active .imgbox{
  top: auto;
  bottom: 0;
} 
.image_with_list_section .list .item:nth-last-child(2).active .imgbox{
  top: auto;
  bottom: 0;
} 
.image_with_list_section .list .item:nth-last-child(3).active .imgbox{
  top: auto;
  bottom: 0;
} 

@media only screen and (max-width: 992px) {
  .image_with_list_section .list .item .text_box {
    max-width: calc(55%);
  }
  .image_with_list_section .list .item .subtitle {
    font-size: 16px;
  }
  .image_with_list_section .list .item .title {
    font-size: 26px;
    line-height: 1;
  }
  .image_with_list_section .list .item .pera {
    font-size: 14px;
    line-height: 1;
  }
  .image_with_list_section .list .item .imgbox {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .image_with_list_section .heading {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .image_with_list_section .list .item {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
  .image_with_list_section .list .item .text_box {
    max-width: 100%;
    text-align: center;
  }
  .image_with_list_section .list .item .subtitle_box {
    justify-content: center;
  }
  /* .image_with_list_section .list .item .pera {
    height: auto;
    opacity: 1;
    visibility: visible;
  } */
  .image_with_list_section .list .item .imgbox {
    position: static;
    opacity: 1;
    visibility: visible;
    width: 100%;
    transform: none;
  }
}
