.blog_grid_section {
  padding: 60px 0;
}
.blog_grid_section .blog_card {
  margin-bottom: 30px;
}
.blog_grid_section .blog_card .head {
  margin-bottom: 30px;
}
.blog_grid_section .blog_card .head .imgbox_full {
  aspect-ratio: 5/3;
  border-radius: 20px;
}
.blog_grid_section .blog_card .body .text_content .date {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 128.5%;
  margin-bottom: 14px;
}
.blog_grid_section .blog_card .body .text_content .title {
  color: #242f65;
  font-family: "Fraunces", serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-bottom: 20px;
}
.blog_grid_section .blog_card .body .text_content .pera {
  color: #525665;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 50px;
}
.blog_grid_section .blog_card .body .text_content .view_more {
  color: #242f65;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%;
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
  gap: 10px;
}
.blog_grid_section .blog_card .body .text_content .view_more .icon_box {
  height: 16px;
  width: 16px;
}
.blog_grid_section .blog_card .body .text_content .view_more .icon_box svg {
  height: 100%;
  width: 100%;
  margin-top: -6px;
}
.blog_grid_section .blog_card .body .text_content .view_more,
.blog_grid_section .blog_card .body .text_content .view_more * {
  cursor: pointer;
}
