._30i9K {
  margin: 0;
  padding: 0;
}

._30i9K * {
  box-sizing: border-box;
}

/* .lightboxjs img,
.lightboxjs svg,
.lightboxjs video {
  display: block;
  vertical-align: middle;
  height: auto;
  max-width: 100%;
} */

._3kSzL {
  background-color: black;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

._tCjNO {
  padding-bottom: 2px;
}

._2IJEr:hover,
._2IJEr:focus,
._2Yv4j svg:hover,
._2Yv4j svg:focus,
._1lgEi span:hover,
._DDceb:hover,
._2Vept:hover {
  text-decoration: none;
  cursor: pointer;
  /* color: rgb(230, 230, 230); */
}

/* On hover, add a black background color with a little bit see-through */
._2gxOL:hover,
._3gp2w:hover {
  background-color: rgba(0, 0, 0, 0.8);
  color: rgb(230, 230, 230);
}

._2crlL {
  display: flex;
}

._34QvR {
  display: none;
}

._1R214 {
  opacity: 1;
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
}

img._3EuZy {
  transition: 0.3s;
}

._3EuZy:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

._DDceb {
  right: 1%;
  padding-left: 2vw;
  padding-right: 3vw;
}

._1lgEi {
  left: 2%;
  padding-right: 2vw;
}


._3pfLz {
  transition: border 0.3s;
}

._1R214 {
  display: inline;
}

._Fp_rP {
  width: 90%;
}

._1jASN {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* Mobile screens */
@media screen and (min-width: 20em) {
  ._22ETc {
    height: 67vw;
    width: 60%;
  }

  ._-wN_b {
    position: fixed;
    bottom: 5%;
    width: 100vw;
    /* width: 100%; */
  }

  ._2b4P7 ._1yWyS {
    height: 100vh;
  }


  ._2b4P7 ._1JRT_ {
    top: 0;
  }

  ._1R214 {
    width: 80px;
    max-width: 80px;
    height: 7vh;
  }

  ._KbOLW {
    bottom: 2%;
  }

  ._1j-pZ {
    padding-bottom: 16px;
    width: 100vw;
  }

  ._3pfLz {
    margin-left: auto;
    margin-right: auto;
  }

  ._DDceb {
    right: 0.9%;
  }

  ._1lgEi {
    left: 1.5%;
  }
}

._1R214 {
  height: 5vh;
  border-radius: 6px;
  margin-right: 7px;
  -o-object-fit: cover;
     object-fit: cover;
}

._-wN_b {
  position: absolute;
  bottom: 10%;
  height: 5vh;
  width: 100vw;
  z-index: 10000000;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

._1Odla {
  width: 78vw;
}

._KbOLW {
  bottom: 5%;
}

._1nxK_ {
  height: 19vh;
}

._z-jNd video {
  height: 100%;
  max-height: 70vh;
  width: 100%;
}

._2jJU2 {
  position: absolute;
  bottom: 14%;
  width: 100vw;
  z-index: 10000000;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

._lY1R_ {
  cursor: pointer;
  margin: 0.2em;
}

._PH6f8 {
  width: 99vw;
  height: 100%;
  max-height: 100%;
  direction: ltr;
  overflow: hidden;
  touch-action: none;
  padding: 0;
  overflow-y: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000;
}

._wTR3b {
  height: 100%;
  max-height: 100%;
  direction: ltr;
  overflow: hidden;
  touch-action: none;
  padding: 0;
  overflow-y: hidden;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  top: 0;
  left: 0;
  z-index: 1000000;
}

._11N04 img {
  height: 100vh;
  max-width: 100%;
  max-height: 100%;
}

._20Hp6 img {
  height: 80vh;
  max-width: 100%;
  max-height: 100%;
}

._20Hp6 {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1000000;
}

._3PObS {
  margin-bottom: 3em;
  margin-left: auto;
  margin-right: auto;
}

._1JRT_ {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: top 0.2s ease-in-out 0s;
  top: 10%;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 1000000;
}

._29Kdn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
}

._1ni2w {
  top: 6%;
}

._GV0NM {
  top: 12%;
}

._2b4P7  {
  top: 0;
}

._11N04 {
  top: 0;
}

._3BNPD {
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

._DDceb,
._1lgEi {
  top: calc(50% - 20px);
  position: absolute;
  display: flex;
  transition: all 0.2s;
  justify-content: center;
  align-items: center;
  padding-bottom: 2vh;
  padding-top: 2vh;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  font-size: 18px;
  z-index: 10000000;
}



._DDceb:hover,
._1lgEi:hover {
  color: rgb(230, 230, 230);
}

._j1nqC {
  position: absolute;
  max-width: 100vw;
}

._ABXHD {
  display: flex;
  flex-direction: row;
  align-items: center;
}

._2Yv4j {
  color: white;
  position: absolute;
  display: flex;
  flex-direction: row;
  /* align-items: center;
    justify-content: center; */
  cursor: pointer;
  top: 10px;
  right: 45px;
  z-index: 999999999;
  /* padding: 0.14vw; */
}

._2eq8V {
  aspect-ratio: 16 / 9;
  height: 65vh;
  width: 100%;
}

._1P3Wc {
  width: 65%;
  margin-left: auto;
  margin-right: auto;
  height: 60vh;
  max-height: 65vh;
}

._z-jNd {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

._Aq8HC, ._w68I8 {
  margin-left: auto;
  margin-right: auto;
}

._qtY0U {
  position: fixed;
  top: 4%;
  right: 3%;
}

._3cxVN ._2Yv4j svg {
  transition: 0.3s all;
}

._2Yv4j svg:active {
  transform: scale(0.97);
}

._2pA2G {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

._2Yv4j svg {
  margin-left: 0.6em;
  padding: 0.2em;
  transition: all 0.1s;
  /* width: 32px;  */
  /* font-size: 18px; */
  line-height: 16px;
  vertical-align: baseline;
}

._2Vept {
  /* width: 32px;  */
}

@media screen and (min-width: 6em) {
  ._2Yv4j {
    top: 3%;
  }

  ._wTR3b {
    width: 100vw;
  }

  ._2Yv4j svg {
    /* width: 34px;  */
    padding-right: 0.3em;
  }

  ._30i9K img {
    cursor: pointer;
  }

  ._1R214 {
    height: 9vh;
    /* width: 22vw; */
  }

  ._DDceb {
    top: calc(48% - 20px);
  }

  ._1lgEi {
    top: calc(48% - 20px);

  }


}

@media screen and (min-width: 39em) {
  ._wTR3b {
    width: 100vw;
  }

  ._1JRT_ {
    width: 100vw;
    height: 95vh;
    position: absolute;
  }

  ._1j-pZ {
    padding-bottom: 0px;
  }

  ._DDceb {
    right: 0.7%;
  }

  /* .hideControlsBar .slideshow_img {
    height: 70vh;

  }

  .hideControlsBar {
    top: 10%;
  } */



  ._11N04 {
    width: 100vw;
    height: 100vh;
  }

  ._11N04 img {
    height: 100vh;
  }

  ._3pfLz {
    margin-top: 0.6em;
  }

  ._1R214 {
    /* height: 8vh; */
    width: 80px;
    max-width: 80px;
  }

  ._2Yv4j svg {
    /* width: 32px; 
      font-size: 32px; */
  }

  ._1lgEi {
    left: 2.7%;
    padding-right: 2vw;
  }
}

/* Desktop screens */
@media screen and (min-width: 42em) {
  ._22ETc {
    height: 70vh;
    width: 80vw;
  }

  ._2yYOi {
    display: grid;
    width: 92vw;
    place-items: start;
    margin-left: auto;
    margin-right: auto;
    scrollbar-gutter: stable both-edges;
  }

  ._1JRT_ img {
    margin-top: 0vh;
    /* width: 70vw; */
  }

  ._11N04 img {
    width: 100vw;
  }

  ._hWNoE {
    top: 0%;
  }

  ._1ek8k {
    margin-left: auto;
    margin-right: auto;
    height: 100vh;
  }

  ._hWNoE {
    height: 100vh;
    width: 100vw;
    position: absolute;
  }

  ._hWNoE img {
    -o-object-fit: contain;
       object-fit: contain;
  }

  ._3pfLz {
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin-top: 1.1em;
  }

  ._3pfLz::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ._2P48G {
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 1;
  }

  ._-wN_b {
    margin-top: 0.1em;
    padding-top: 0.3em;
    height: 5vh;
    bottom: 11%;
    /* width: 90vw; */
  }

  ._1R214,
  ._1xBL9 img {
    height: 10vh;
    /* width: 7em; */
  }

  ._wTR3b {
    width: 100vw;
  }

  ._1JRT_ {
    width: 100vw;
    height: 95vh;
  }

  ._2b4P7 {
    height: 100vh;
  }

  ._11N04 {
    width: 100vw;
    height: 100vh;
  }

  ._2Yv4j svg {
    /* width: 37px;
      font-size: 34px; */
  }

  ._1lgEi {
    left: 2%;
    padding-right: 2vw;
  }

  ._DDceb {
    right: 0.4%;
  }

  
  ._15MH_ {
    width: 78vw;
  }

  ._3JYCS {
    left: 1%;
  }

  ._1jASN {
    gap: 8px;
  }
}

@media screen and (min-width: 53em) {
  
  ._1yWyS {
    display: grid;
    width: 100vw;
    height: 70vh;
    place-items: start;
    /* margin-top: 7vh; */
  }

  ._2b4P7 ._1yWyS, ._31Tcx ._1yWyS  {
    padding-top: 12vh;
  }


  ._3A0RO {
      /* height: 70vh; */
  }

  ._1R214 {
    width: 95px;
    max-width: 80px;
  } 

  ._15MH_ {
    width: 78vw;
  }
}
  

@media screen and (min-width: 80em) {
  ._1JRT_ img {
    /* width: 60vw; */
  }

  ._11N04 img {
    width: 100vw;
  }
}

._1kIQ4,
._1kIQ4 svg {
  background-color: white;
  color: black;
}

._1kIQ4:hover,
._1kIQ4 svg:hover {
  background-color: white;
  color: black;
}

._1qbJe,
._1qbJe svg {
  background-color: transparent;
  /* background-color: #151515; */
  color: silver;
}

._1qbJe:hover,
._1qbJe svg:hover {
  color: rgba(107, 107, 107, 0.8);
}

/* Header icons  */
._3DIHj svg {
  background-color: white;
  color: black;
}

._3DIHj svg:hover {
  background-color: white;
  color: black;
  color: rgb(24, 24, 24, 0.8);
}

._11aT5 svg {
  background-color: transparent;
  /* background-color: #151515; */
  color: #626b77;
}

._11aT5 svg:hover {
  color: rgba(99, 99, 99, 0.8);
}

._mdJQi {
  -o-object-fit: contain;
     object-fit: contain;
}

._3pfLz ._3sodH {
  border: solid rgb(107, 133, 206) 2px;
}

._3bkUp {
  border: solid transparent 2px;
}

._3pfLz {
  width: 95%;
}

._32vVi {
  --thumbs-slide-spacing: 2px; 
  /* --thumbs-slide-height: 9rem; */
  margin-top: var(--thumbs-slide-spacing);
}

@media screen and (max-width: 29em) {
  ._11N04 img {
    -o-object-fit: cover;
       object-fit: cover;
  }

  ._32vVi {
    --thumbs-slide-spacing: 3px; 
    /* --thumbs-slide-height: 9rem; */
    margin-top: var(--thumbs-slide-spacing);
  }

  ._sqvn- {
    margin-top: 5vh;
  }

  ._3pfLz {
    width: 98%;
  }

  ._1R214 {
    width: 80px;
    max-width: 80px;
  }

  ._z-jNd video {
    max-height: 65vh;
  }

  ._qtY0U {
    position: fixed;
    top: 2%;
    right: 15px;
  }

  ._1P3Wc {
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    max-height: 65vh;
  }

  ._z-jNd {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
  }

  ._2Yv4j {
    position: fixed;
    left: 115px;
  }

  ._1ni2w {
    top: 3%;
  }

  ._DDceb,
  ._1lgEi {
    padding-bottom: 2vh;
    padding-top: 2vh;
  }

  ._DDceb {
    padding-left: 4vw;
  }

  ._1lgEi {
    padding-right: 4vw;
  }

  ._2Yv4j {
    /* font-size: 28px; */
  }


}

@media screen and (max-width: 53em) {
  ._1yWyS {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 70vh;
  }

  ._1yWyS img {
    margin-left: auto;
    margin-right: auto;
  }

  ._2yYOi {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 70vh;
  }

  ._hWNoE {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: top 0.2s ease-in-out 0s;
    top: 10%;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1000000;
  }

  ._21mY1 {
    -o-object-fit: contain;
       object-fit: contain;
  }

}

._11N04 img {
  -o-object-fit: cover;
     object-fit: cover;
}

._3pfLz {
  text-align: center;
  white-space: nowrap;
}

._3Id1- {
  margin-left: auto;
  margin-right: auto;
}

._mdJQi {
  margin-left: auto;
  margin-right: auto;
}

/* CSS loader  */
._3qqrl {
  width: 44px;
  height: 44px;
  box-sizing: border-box;

  border-radius: 50%;

  display: inline-block;
  position: fixed;
  top: 50%;
  left: 50%;
  animation: _1hUzk 1s linear infinite;
}

@keyframes _1hUzk {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

._1itZw {
  border: 5px solid #626b77;
  border-bottom-color: transparent;
}

._2TI_i {
  border: 5px solid #fff;
  border-bottom-color: transparent;
}

._3A0RO {
  max-height: 70vh;
  margin-left: auto;
  margin-right: auto;
}

._ABXHD button, ._2kumA, ._3aFDN {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
}

._ABXHD svg {
  box-sizing: border-box;
}

._2kumA svg, ._3aFDN svg {
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 0.5px;
}

._2kumA {
  padding-top: 3px;
}

._3aFDN {
  cursor: pointer;

}

._3aFDN svg {
  margin: 0;
  padding: 5px;
  background-color: black;
  border-color: transparent;
  border-radius: 28px;

}

._1nxK_ {
  bottom: 0%;
  height: 19vh;
  margin-top: 0em;
  width: 100vw;
}

._Fp_rP {
  margin-top: 0em;
}

._238y5 {
  top: 7%;
}

._238y5._2b4P7 {
  top: 0;
}

._wTR3b {
  -ms-overflow-style: none;
  /* scrollbar-width: none;   */
}
._3pfLz::-webkit-scrollbar {
  display: none;
}

._2FvXV {
  border-radius: 20px;
}

._oa5b0 {
  max-width: 100%;
}

._z-bHV {
  max-width: 80%;
}

@media screen and (max-width: 880px) {
  ._2Yv4j {
    position: fixed;
    right: 5%;
  }
}

@media screen and (max-width: 480px) {
  ._2Yv4j {
    position: fixed;
    left: 180px;
  }
}

@media screen and (max-width: 440px) {
  ._2Yv4j {
    position: fixed;
    left: 160px;
  }
}

@media screen and (max-width: 410px) {
  ._2Yv4j {
    position: fixed;
    left: 140px;
  }
}

@media screen and (max-width: 385px) {
  ._2Yv4j {
    position: fixed;
    left: 135px;
    /* top: 24px; */
  }
}

@media screen and (max-width: 365px) {
  ._2Yv4j {
    position: fixed;
    left: 90px;
  }
}

@media screen and (max-width: 345px) {
  ._2Yv4j {
    position: fixed;
    left: 82px;
  }
}

@media screen and (max-width: 325px) {
  ._2Yv4j {
    position: fixed;
    left: 60px;
  }
}

@media screen and (max-width: 300px) {
  ._2Yv4j {
    position: fixed;
    left: 30px;
  }
}

._hGbhv {
  margin-left: auto;
  margin-right: auto;
}

[class^='number-slide'],
[class*=' number-slide'] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

._1dVqh {
  position: relative;
}

._3MuXE {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

._3U2py {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

._3U2py:focus {
  outline: none;
}

._3U2py._3sodH {
  background: #000;
}

._35l6Q {
  position: absolute;
  top: 50%;
  font-size: 24px;
  font-weight: bold;
  transition: all 0.2s;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: sans-serif;
}

._1I4Zg {
  left: 8px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

._1lqBv {
  left: auto;
  right: 8px;
  padding-left: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

._35l6Q:hover {
  color: rgb(230, 230, 230);
}

._37Xda,
._37Xda:hover {
  color: rgba(255, 255, 255, 0.5);
}

._1LtID {
  margin-top: 12px;
}

._2y9OU {
  border: 'solid transparent 2px';
}

._2oAIc {
  width: 100%;
  height: 100%;
}

._2oAIc iframe {
  width: 100%;
  height: 100%;
}

._2-c5L {
  height: 100%;
}

._30i9K iframe {
  margin: none;
  padding: none;
  border: none;
}

._1Bey- {
  z-index: 100000;
  background-color: transparent;
  border: none;
}

._3pfLz {
  margin-left: auto;
  margin-right: auto;
}

._3xuz3 {
  cursor: pointer;
}

._1fpgA > * { color: inherit; fill: inherit }

._1cifY {
  margin-left: auto;
  margin-right: auto;
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 19rem;
}

._1mBUR {
  overflow: hidden;
  width: 100vw;
}

._Bqct9 {
  backface-visibility: hidden; 
  touch-action: pan-y;
  height: auto;
  margin-left: calc(var(--slide-spacing) * -1);
  display: grid;  
  grid-auto-flow: column;  
  grid-auto-columns: 100%; 
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto; 
  /* width: 80%;
*/
}

._39UN- {
  width: 78vw;
  max-width: 78vw;
}

._lqLcV ._1eJNe {
  flex: 0 0 80%;
  min-width: 0;
  max-width: 100%;
  padding-left: 12px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

._1tmVU {
  overflow: hidden;
}

._3-Y1e {
  display: flex;
  flex-direction: row;
  margin-left: calc(var(--thumbs-slide-spacing) * -1);
  width: 100%;
  /* align-items: center;
  text-align: center;
  width: fit-content; */
  /* justify-content: center; */
  
}

._3-Y1e::before, ._3-Y1e::after {
  content: '';  /* Insert pseudo-element */
  margin: auto; /* Make it push flex items to the center */
}


._jp0uN {
  /* min-width: 0; */
  padding-left: var(--thumbs-slide-spacing);
  /* position: relative; */
  /* width: 140px; */
}

._3S-ie {
  /* display: block; */
  height: var(--thumbs-slide-height);
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

._1afvv {
  margin-left: auto;
}

._1HGLk {
  color: white;
}

._1TshF {
  display: flex;
}

._1HGLk {
  text-align: left;
  min-width: 17vw;
  max-width: 17vw;
  padding-top: 5vw;
}

._U_FmQ {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

._1Y4P9 {
  padding-left: 7vw;
}

._1Y4P9 span {
  display: block;
}


._1vmhr {
  position: absolute;
  top: 1%;
  right: 2%;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
}

/* Styling from react-zoom-pan-pinch library */
._3djxx {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */ /* Konqueror HTML */ /* Internet Explorer/Edge */
  user-select: none;
  margin: 0;
  padding: 0;
}

._2UUxD {
  display: flex;
  flex-wrap: wrap;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}

._2UUxD img {
  pointer-events: none;
}

._gMqOZ {
  height: 10px;
  width: 10px;
  background-color: silver;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

._1KxdK {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 12px;
  padding-right: 12px;
}

._1mIvU._Bqct9 {
  transform: none !important; /* override Embla */
  display: flex;
  height: 250px;
}

._1mIvU._1eJNe {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0 !important; /* override Embla */
  right: 0 !important; /* override Embla */
  opacity: 0;
  transition: opacity 0.5s;
  counter-increment: embla;
  border-radius: 0.5rem;
}

._1mIvU._1eIZ- {
  opacity: 1;
  transition: opacity 0.3s;
  z-index: 1;
}

._1mIvU._1eJNe:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}