/* td {
  width: 15%;
} */
@media (max-width: 650px) {
  table:not(.table-bordered).no-first-header.show-serial
    td:first-child:not(:last-child)::before {
    content: "SL.";
  }
  .table:not(.table-bordered) td:first-child,
  .table:not(.table-bordered) th:first-child,
  .table:not(.table-bordered) tr:first-child {
    padding-left: 8.8px;
  }
  th {
    display: none;
  }

  td:not(:last-child) {
    display: grid;
    grid-template-columns: 17ch auto;
    word-break: break-word;
    width: 100%;
  }
  table.priceing td:not(:last-child) {
    grid-template-columns: unset !important;
  }

  td::before {
    content: attr(data-header) " ";
    font-weight: 600;
    text-transform: capitalize;
    color: #3d3d3d;
  }
  table tbody td:last-child:not([colspan]) {
    display: grid;
    grid-template-columns: 17ch 5ch 5ch 5ch;
    align-items: center;
    text-align: start !important;
    width: 100%;
  }
  table tbody td[data-label]::before {
    content: attr(data-label) " ";
    font-weight: 600;
    text-transform: capitalize;
    color: #3d3d3d;
  }
  td[data-label] {
    text-align: start !important;
    display: grid;
    grid-template-columns: 20ch auto;
    align-items: center;
    width: 100%;
  }
}
