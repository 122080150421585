.about_banner_section {
  background-image: url("./../../../../../public/image/about-banner.png");
  margin-top: 80px;
  background-size: fit;
  background-position: right;
  background-repeat: no-repeat;
  background-color: #f0f1f7;
}
.about_banner_section .text_content_wrapper .text_content {
  min-height: 70vh;
  padding: 100px 0;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about_banner_section .text_content_wrapper .text_content .badge {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.1px;
  border-radius: 20px;
  background: rgba(75, 78, 103, 0.4);
  padding: 2px 8px;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 12px;
}
.about_banner_section .text_content_wrapper .text_content .heading {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 52px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: -1.04px;
  margin-bottom: 24px;
}
.about_banner_section .text_content_wrapper .text_content .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.1px;
  margin-bottom: 32px;
}
.about_banner_section .text_content_wrapper .text_content .button_box {
  display: flex;
  align-items: center;
  gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn {
  border-radius: 6px;
  background: #4b4e67;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  padding: 12px 20px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 250ms;
}
.about_banner_section
  .text_content_wrapper
  .text_content
  .button_box
  .btn
  .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.about_banner_section
  .text_content_wrapper
  .text_content
  .button_box
  .btn
  .icon_box
  svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.about_banner_section
  .text_content_wrapper
  .text_content
  .button_box
  .btn
  .icon_box
  svg
  * {
  stroke: currentColor;
}
.about_banner_section .text_content_wrapper .text_content .button_box .btn,
.about_banner_section .text_content_wrapper .text_content .button_box .btn * {
  cursor: pointer;
}
.about_banner_section
  .text_content_wrapper
  .text_content
  .button_box
  .btn:hover {
  border: 1px solid #e5e5e7;
  background: #fff;
  color: #323539;
}
