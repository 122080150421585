.home_banner_section {
  /* background-image: url("./../../../../../public/image/home-banner.png"); */
  margin-top: 80px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #4B4E67;
}
.home_banner_section .content_wrapper {
  padding: 60px 0;
}
.home_banner_section .content_wrapper .subheading {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 24px 0;
}
.home_banner_section .content_wrapper .subheading,
.home_banner_section .content_wrapper .subheading * {
  cursor: pointer;
}
.home_banner_section .content_wrapper .subheading .icon_box {
  width: 24px;
  height: 24px;
  display: flex;
}
.home_banner_section .content_wrapper .subheading .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.home_banner_section .content_wrapper .heading {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  max-width: 700px;
  margin-bottom: 32px;
}

.home_banner_section .content_wrapper .pera {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  max-width: 700px;
  margin-bottom: 32px;
}

.home_banner_section .content_wrapper .button_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 25px;
  row-gap: 25px;
}
.home_banner_section .content_wrapper .button_box .c_btn {
  padding: 15px 25px;
  border-radius: 3px;
  background: #fff;
  color: #4b4e67;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
}

.home_banner_section .content_wrapper .button_box .c_link {
  color: #fff;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
}

.home_banner_section .content_wrapper .button_box .c_btn,
.home_banner_section .content_wrapper .button_box .c_btn *,
.home_banner_section .content_wrapper .button_box .c_link,
.home_banner_section .content_wrapper .button_box .c_link * {
  cursor: pointer;
}
.home_banner_section .content_wrapper .imgbox{
  max-width: 400px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .home_banner_section {
    padding: 60px 0;
    background-size: cover;
  }
  .home_banner_section .content_wrapper {
    padding: 0px 0;
  }
  .home_banner_section .content_wrapper .subheading {
    font-size: 16px;
    line-height: 20px;
  }
  .home_banner_section .content_wrapper .heading {
    font-size: 22px;
    line-height: 25px;
  }
  .home_banner_section .content_wrapper .input_box button .text {
    display: none;
  }
}
