.product_info_section {
  padding: 94px 0 60px;
}
.product_info_section .cm_title_box {
  margin-bottom: 40px;
}
.product_info_section .product_info_tabs .tab_triggers {
  display: flex;
  gap: 16px;
}
.product_info_section .product_info_tabs .tab_triggers .trigger {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 0 12px 0;
  margin-bottom: 16px;
  position: relative;
}
.product_info_section .product_info_tabs .tab_triggers .trigger span {
  margin-left: 16px;
  border-radius: 4px;
  background-color: #3c4242;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 6px;
}
.product_info_section .product_info_tabs .tab_triggers .trigger::before {
  content: "";
  width: 0px;
  opacity: 0;
  visibility: 0;
  height: 1.5px;
  background-color: #000000;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 250ms;
}
.product_info_section .product_info_tabs .tab_triggers .trigger.active::before {
  width: 40px;
  opacity: 1;
  visibility: visible;
}
.product_info_section .product_info_tabs .tab_triggers .trigger,
.product_info_section .product_info_tabs .tab_triggers .trigger * {
  cursor: pointer;
}
.product_info_section .product_info_tabs .tab_contents .content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms;
}
.product_info_section .product_info_tabs .tab_contents .content.active {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 250ms;
}
.product_info_section .product_info_tabs .description_content .description {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.32px;
  margin-bottom: 30px;
}
.product_info_section .product_info_tabs .description_content .title {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table,
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table
  tr,
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table
  td {
  border: 0.3px solid rgba(0, 0, 0, 0.4);
  border-collapse: collapse;
}
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table
  tr
  td {
  padding: 16px 24px;
}
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table
  tr
  td
  h3 {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  margin-bottom: 12px;
}
.product_info_section
  .product_info_tabs
  .description_content
  .table_wrapper
  .specification_table
  tr
  td
  h2 {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.32px;
}
.product_info_section .product_info_tabs .faq_content {
  margin-bottom: 12px;
}
.product_info_section .product_info_tabs .faq_content .faq {
  margin-bottom: 20px;
}
.product_info_section .product_info_tabs .faq_content .faq .question {
  color: #3c4242;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.product_info_section .product_info_tabs .faq_content .faq .answere {
  color: #807d7e;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0.32px;
}
.product_info_section .product_media_tabs .tab_triggers {
  display: flex;
  margin-bottom: 30px;
}
.product_info_section .product_media_tabs .tab_triggers .trigger {
  width: 50%;
  color: #4b4e67;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  padding: 16px;
  text-align: center;
}
.product_info_section .product_media_tabs .tab_triggers .trigger,
.product_info_section .product_media_tabs .tab_triggers .trigger * {
  cursor: pointer;
}
.product_info_section .product_media_tabs .tab_triggers .trigger.active {
  border-bottom: 2px solid #4b4e67;
}
.product_info_section .product_media_tabs .tab_contents .content {
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 250ms;
}
.product_info_section .product_media_tabs .tab_contents .content.active {
  height: auto;
  opacity: 1;
  visibility: visible;
  transition-delay: 250ms;
}
.product_info_section .product_media_tabs .video_content {
  width: 100%;
  position: relative;
  padding: 5px;
}
.product_info_section .product_media_tabs .video_content video {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.product_info_section .product_media_tabs .video_content .thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
}
.product_info_section .product_media_tabs .video_content .thumbnail .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(60, 66, 66, 0.2509803922);
  position: absolute;
  left: 0;
  top: 0;
}
.product_info_section .product_media_tabs .video_content .thumbnail .play_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}
.product_info_section .product_media_tabs .video_content .thumbnail .play_btn,
.product_info_section
  .product_media_tabs
  .video_content
  .thumbnail
  .play_btn
  * {
  cursor: pointer;
}
.product_info_section
  .product_media_tabs
  .video_content
  .thumbnail
  .video_title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.44px;
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
}
.product_info_section
  .product_media_tabs
  .video_content
  .thumbnail
  .video_length {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
  position: absolute;
  top: 20px;
  right: 20px;
}
