.faq_section {
  margin-top: 80px;
  padding: 64px 0;
}
.faq_section .heading {
  color: #323539;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.48px;
}
.faq_section .pera {
  color: #353535;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.18px;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 64px;
}
.faq_section .accordion_box .accordion_item {
  border-radius: 6px;
  border: 1px solid #e5e5e7;
  margin-bottom: 16px;
  overflow: hidden;
}
.faq_section .accordion_box .accordion_item .accordion_title {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title {
  background-color: #4b4e67;
}
.faq_section .accordion_box .accordion_item .accordion_title,
.faq_section .accordion_box .accordion_item .accordion_title * {
  cursor: pointer;
}
.faq_section .accordion_box .accordion_item .accordion_title .title {
  color: #323539;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.16px;
  margin-bottom: 0;
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .title {
  color: #fff;
}
.faq_section .accordion_box .accordion_item .accordion_title .icon_box {
  width: 20px;
  height: 20px;
  color: #323539;
  transform: rotate(180deg);
  transition: all linear 350ms;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .icon_box {
  color: #fff;
}
.faq_section .accordion_box .accordion_item .accordion_title .icon_box svg {
  stroke: currentColor;
  margin-top: -6px;
}
.faq_section
  .accordion_box
  .accordion_item
  .accordion_title
  .icon_box
  svg
  path {
  stroke: currentColor;
}
.faq_section .accordion_box .accordion_item .accordion_content {
  height: 0px;
  overflow: hidden;
  transition: all linear 350ms;
}
.faq_section
  .accordion_box
  .accordion_item
  .accordion_content
  .content_wrapper {
  padding: 12px 20px 24px 20px;
}
.faq_section .accordion_box .accordion_item .accordion_content .pera {
  color: #858c95;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  text-align: left;
  margin-bottom: 0;
}
.faq_section .accordion_box .accordion_item.active .accordion_title .icon_box {
  transform: rotate(0deg);
}
