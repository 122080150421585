.resources_section {
  padding: 34px 0 68px;
  background-color: #f0f1f7;
}
.resources_section .subheading {
  color: #808183;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 16px;
}
.resources_section .heading {
  color: #2b273c;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44.8px;
  margin-bottom: 48px;
}
.resources_section .resource_card {
  border-radius: 16px;
  background: #fff;
  padding: 32px;
  height: 100%;
}
.resources_section .resource_card  .head{
  margin-bottom: 24px;
  aspect-ratio: 2 / 1.2;
}
.resources_section .resource_card  .head .imgbox{
  aspect-ratio: 2 / 1.2;
}
.resources_section .resource_card .body .title {
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}
.resources_section .resource_card .body .pera {
  color: #2b273c;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.resources_section .resource_card .body .btn {
  display: flex;
  align-items: center;
  color: #00838f;
  font-family: "Google Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 0 !important;
}
.resources_section .resource_card .body .btn .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.resources_section .resource_card .body .btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.resources_section .resource_card .body .btn,
.resources_section .resource_card .body .btn * {
  cursor: pointer;
}
