.feature_card_section {
  padding: 40px 0;
  background-color: #555872;
  background-image: url("./../../../../../public/image/bg.png");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.feature_card_section .feature_card {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.08);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  padding: 40px;
}
.feature_card_section .feature_card .head {
  margin-bottom: 20px;
}
.feature_card_section .feature_card .head .icon_box {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.12);
}
.feature_card_section .feature_card .body .title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}
.feature_card_section .feature_card .body .pera {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.feature_card_section .search_box {
  padding: 40px;
  border-radius: 48px;
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(15px);
}

.feature_card_section .search_box_wrapper .input_box {
  width: 100%;
  border-radius: 1000px;
  background-color: #fff;
  display: flex;
  padding: 10px 15px 10px 15px;
  margin-bottom: 32px;
  position: relative;
}
.feature_card_section .search_box_wrapper .input_box input {
  border: none;
  color: #767676;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  width: 100%;
  cursor: text;
}
.feature_card_section .search_box_wrapper .input_box button {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 26px;
  background: #4b4e67;
  padding: 13px 20px;
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  position: relative;
  overflow: hidden;
}
.feature_card_section .search_box_wrapper .input_box button::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.feature_card_section .search_box_wrapper .input_box button:hover::before {
  width: 100%;
}
.feature_card_section .search_box_wrapper .input_box button,
.feature_card_section .search_box_wrapper .input_box button * {
  cursor: pointer;
}
.feature_card_section .search_box_wrapper .input_box button .icon_box {
  width: 20px;
  height: 20px;
  display: flex;
}
.feature_card_section .search_box_wrapper .input_box button .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
}
.feature_card_section .search_box_wrapper .tag_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 20px;
}
.feature_card_section .search_box_wrapper .tag_box .title {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
.feature_card_section .search_box_wrapper .tag_box .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  row-gap: 20px;
}
/* .feature_card_section .search_box_wrapper .tag_box .tags li a {
  border-radius: 22px;
  border: 1px solid #fff;
  padding: 10px 70px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
} */

.feature_card_section .search_box_wrapper .tag_box .tags li a {
  display: block;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  max-width: 100%; /* Prevent tag from exceeding its container */
  border-radius: 22px;
  border: 1px solid #fff;
  padding: 10px 30px; /* Adjust padding */
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .feature_card_section .search_box_wrapper .tag_box .tags li a {
    padding: 8px 20px; /* Reduce padding for small screens */
    font-size: 12px; /* Adjust font size for better fit */
  }
}

.feature_card_section .search_box_wrapper .tag_box .tags li a::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  border-radius: 22px;
  transform: translate(-50%, -50%);
  width: 0%;
  height: 100%;
  background-color: #11111150;
  transition: all linear 100ms;
}
.feature_card_section .search_box_wrapper .tag_box .tags li a:hover::before {
  width: 100%;
}
.feature_card_section .search_box_wrapper .tag_box .tags li a {
  color: #fff;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .feature_card_section .search_box {
    padding: 20px;
    border-radius: 20px;
  }
  .feature_card_section {
    background-size: cover;
    padding: 30px 0;
  }
  .feature_card_section .search_box_wrapper .input_box {
    padding: 5px 15px;
  }
  .feature_card_section .search_box_wrapper .tag_box .tags li {
    padding: 10px 30px;
  }
  .feature_card_section .search_box_wrapper .input_box button .text {
    display: none;
  }
}
