.price_range_slector {
  padding: 16px;
}

.price_range_slector .price-input {
  width: 100%;
  display: flex;
  margin: 30px 0 35px;
}

.price_range_slector .price-input .field {
  display: flex;
  width: 100%;
  height: 45px;
  align-items: center;
}

.price_range_slector .field input {
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 19px;
  margin-left: 12px;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #999;
  -moz-appearance: textfield;
}

.price_range_slector input[type="number"]::-webkit-outer-spin-button,
.price_range_slector input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.price_range_slector .price-input .separator {
  width: 130px;
  display: flex;
  font-size: 19px;
  align-items: center;
  justify-content: center;
}

.price_range_slector .slider {
  height: 5px;
  position: relative;
  background: #ddd;
  border-radius: 5px;
}

.price_range_slector .slider .progress {
  height: 100%;
  left: 25%;
  right: 25%;
  position: absolute;
  border-radius: 5px;
  background: #4b4e67;
}

.price_range_slector .range-input {
  position: relative;
}

.price_range_slector .range-input input {
  position: absolute;
  width: 100%;
  height: 5px;
  top: -5px;
  background: none;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.price_range_slector input[type="range"]::-webkit-slider-thumb {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: #4b4e67;
  pointer-events: auto;
  -webkit-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  cursor: grab;
}

.price_range_slector input[type="range"]::-moz-range-thumb {
  height: 17px;
  width: 17px;
  border: none;
  border-radius: 50%;
  background: #4b4e67;
  pointer-events: auto;
  -moz-appearance: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  cursor: grab;
}
