.product_grid_section {
  padding: 60px 0;
}
.product_grid_section .heading {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
.product_grid_section .discover_card {
  border-radius: 16px;
  background: #f0f1f7;
  padding: 20px 10px;
  height: 100%;
}
.product_grid_section .discover_card .head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  row-gap: 10px;
  margin-bottom: 20px;
}
.product_grid_section .discover_card .head .left_box .title {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.product_grid_section .discover_card .head .left_box .subtitle {
  color: #222;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.product_grid_section .discover_card .head .right_box .view_more {
  color: #333;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  text-underline-offset: 4px;
}
.product_grid_section .discover_card .head .right_box .view_more,
.product_grid_section .discover_card .head .right_box .view_more * {
  cursor: pointer;
}
.product_grid_section .product_grid_card .head {
  margin-bottom: 15px;
}
.product_grid_section .product_grid_card .head .imgbox_full {
  border-radius: 35px;
}
.product_grid_section .product_grid_card .body {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 20px;
}
.product_grid_section .product_grid_card .body .title_box .badge {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0;
}
.product_grid_section .product_grid_card .body .title_box .title {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.product_grid_section .product_grid_card .body .price_box .price {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.product_grid_section .product_grid_card.lg_image .imgbox_full {
  aspect-ratio: 1/1;
}
.product_grid_section .product_grid_card.md_image .imgbox_full {
  aspect-ratio: 2/3;
}
.product_grid_section .product_grid_card.sm_image .imgbox_full {
  aspect-ratio: 2/1;
}

@media only screen and (max-width: 768px) {
  .product_grid_section .product_grid_card.lg_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .product_grid_card.md_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .product_grid_card.sm_image .imgbox_full {
    aspect-ratio: 1/1;
  }
  .product_grid_section .heading {
    font-size: 26px;
  }
}
