.blog_banner_section {
  padding: 120px 0;
  background-image: url("./../../../../../public/image/blog-banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  text-align: center;
}
.blog_banner_section .back_btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #2a2c32;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 128.5%;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto 23px;
}
.blog_banner_section .back_btn .icon_box {
  width: 20px;
  height: 20px;
}
.blog_banner_section .back_btn .icon_box svg {
  width: 100%;
  height: 100%;
  color: currentColor;
  margin-top: -6px;
}
.blog_banner_section .back_btn,
.blog_banner_section .back_btn * {
  cursor: pointer;
}
.blog_banner_section .title {
  color: #242f65;
  text-align: center;
  font-family: "Fraunces", serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}
